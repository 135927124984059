var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleEx,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
            title: "Gửi thông báo qua App cho học sinh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleEx = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createStudentNotify",
              attrs: {
                "label-width": "140px",
                model: _vm.createStudentNotify,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tiêu đề", prop: "sendTitle" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.createStudentNotify.sendTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createStudentNotify,
                                    "sendTitle",
                                    $$v
                                  )
                                },
                                expression: "createStudentNotify.sendTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung", prop: "sendContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 7,
                                type: "textarea",
                                placeholder: "Nhập nội dung...",
                              },
                              model: {
                                value: _vm.createStudentNotify.sendContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createStudentNotify,
                                    "sendContent",
                                    $$v
                                  )
                                },
                                expression: "createStudentNotify.sendContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "139px",
                              color: "#eb2020",
                            },
                          },
                          [
                            _vm._v(
                              "Nhà trường có thể chọn đính kèm thông tin về học phí để PH nắm được chi tiết."
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.toggleAttachMessage },
                                    model: {
                                      value:
                                        _vm.createStudentNotify
                                          .statusAttachNotifyFinance,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.createStudentNotify,
                                          "statusAttachNotifyFinance",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "createStudentNotify.statusAttachNotifyFinance",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Đính\n                  kèm thông tin học phí\n                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.createStudentNotify.attachOrderPDF,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.createStudentNotify,
                                          "attachOrderPDF",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "createStudentNotify.attachOrderPDF",
                                    },
                                  },
                                  [_vm._v("Đính kèm ảnh hóa đơn học phí")]
                                ),
                              ],
                              1
                            ),
                            _vm.showAttachMessage
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#eb2020" } },
                                    [
                                      _vm._v(
                                        "* Nội dung của bạn sẽ được đính kèm theo cả thông báo học phí!"
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "File đính kèm",
                              prop: "attachFile",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        ref: "upload",
                                        attrs: {
                                          action: "",
                                          "on-remove": _vm.handleRemove,
                                          "before-remove": _vm.beforeRemove,
                                          "on-change": _vm.toggleUpload,
                                          "auto-upload": false,
                                          multiple: "",
                                          limit: 5,
                                          "on-exceed": _vm.handleExceed,
                                          "file-list": _vm.fileList,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("Tải lên")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Nhà trường có thể tải thêm file bổ sung nếu cần."
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("createStudentNotify")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Gửi")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("createStudentNotify")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }