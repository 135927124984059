<template>
  <div>
    <div class="search-table">
      <el-date-picker
          style="width: 115px"
          class="custom-date-picker"
          :clearable="false"
          v-model="monthAndYear"
          type="month"
          @change="handelUpdateDate"
          placeholder="Chọn tháng"
          value-format="M-yyyy"
          format="MM-yyyy"
          :time-arrow-control="false"
      >
      </el-date-picker>
      <!-- chọn trạng thái -->
      <el-select
          @change="handleChangeKidStatus"
          style="width: 120px"
          class=""
          v-model="dataSearch.kidStatus"
          placeholder="Trạng thái"
      >
        <el-option
            v-for="(item,index) in kidStatusListExcel"
            :key="index"
            :value="item.key"
            :label="item.value"
        ></el-option>
      </el-select>

      <!-- chọn khối -->
      <el-select
          @change="handleChangeKidGrade"
          class=" input-grade"
          style="width: 160px"
          v-model="dataSearch.idGrade"
          placeholder="Chọn khối"
      >
        <el-option
            v-for="(item,index) in gradeList"
            :key="index"
            :value="item.id"
            :label="item.gradeName"
        ></el-option>
      </el-select>

      <!-- chọn lớp -->
      <el-select
          style="width: 160px"
          v-model="dataSearch.idClass"
          @change="handleChangeKidClass"
          placeholder="Chọn lớp"
      >
        <el-option
            v-for="item in classList"
            :key="item.id"
            :value="item.id"
            :label="item.className"
        >
        </el-option>
      </el-select>
      <!-- trạng thái -->
      <el-select
          @change="handleChangeStatus"
          @remove-tag="handleChangeStatus"
          @clear="handleChangeStatus"
          style="width: 203px"
          placeholder="Chọn trạng thái"
          v-model="status"
          multiple
          collapse-tags
          clearable
      >
        <el-option
            v-for="(item, index) in statusOrderList"
            :label="item"
            :key="index"
            :value="item"
        >
        </el-option>
      </el-select>
      <el-input
          @clear="handleClearKidName"
          clearable
          style="width: 240px"
          placeholder="Nhập tên, biệt danh"
          v-model="kidName"
          @keyup.enter.native="handleChangeKidName"
      >
        <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearchResultByName"
        ></el-button>
      </el-input>
      <el-select
          @change="handleChangeCollectionTurn"
          style="width: 100px"
          class=""
          v-model="dataSearch.collectionTurn"
          placeholder="Đợt thu"
      >
        <el-option
            v-for="item in collectionTurn"
            :key="item"
            :value="item"
            :label="`Đợt ${item}`"
        ></el-option>
      </el-select>
      <el-button
          style="float: right"
          class="custom_button"
          type="success"
          icon="el-icon-plus"
          size="medium"
          v-if="checkRevenue"
          @click="handleOpenAddNameExpensesDialog"
      >
        Thêm khoản
      </el-button>
      <span class="loading_title" v-if="loadingBill === true" style="color: red;	text-align: right;">Dữ liệu đang được xử lý... <span
          v-loading="loadingBill"></span> </span>
      <span class="loading_title" v-if="loadingUpdate === true">Đang cập nhật điểm danh... <span
          v-loading="loadingUpdate"></span></span>
      <span class="loading_title" v-if="bulkPayments === true">Đang cập nhật thanh toán toàn bộ... <span
          v-loading="bulkPayments"></span></span>
    </div>
    <slot></slot>
    <el-row style="margin-top: 15px ">

      <el-col :span="20" :offset="4" style=" display: inline-flex;justify-content: right ">
        <el-pagination
            style=" margin-right: 5.2rem;margin-left: auto"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPagePagination"
            :page-sizes="listSizeLimit"
            :page-size="sizeLimit"
            layout=" prev, pager,sizes, next"
            :total="sizeLimit * totalPages"
        >
        </el-pagination>
        <el-menu
            style="margin-right: 1.2rem"
            v-if="checkPermission(['fees_2_excel_operation'])"
            background-color="#67C23A"
            text-color="#FFFFFF"
            active-text-color="#FFFFFF"
            :unique-opened="true"
            menu-trigger="hover"
            mode="horizontal"
            @select="handleCommand"
        >
          <el-submenu index="1">
            <template slot="title">Tác vụ</template>
            <el-menu-item index="updateAttendanceData" v-if="updateAttendance === true">Cập nhật điểm danh
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">Hóa đơn</template>
              <el-menu-item index="2-createInvoice">Khởi tạo khoản thu</el-menu-item>
              <el-menu-item index="2-pdfPreview">In hóa đơn 1 liên</el-menu-item>
              <el-menu-item index="2-pdfSplit">In hóa đơn 2 liên</el-menu-item>
              <el-menu-item index="2-exportAllBill"
              >Xuất hóa đơn theo tháng
              </el-menu-item
              >
              <el-menu-item index="2-deleteOrder">Hủy hóa đơn</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-openOrder">Khôi phục hóa đơn</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-destroyOrder">Xóa hóa đơn</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">Xuất học phí tổng hợp</template>
              <el-menu-item index="3-exportExcel">Theo đợt đã chọn</el-menu-item>
              <el-menu-item index="3-exportAllExcel">Theo tất cả các đợt</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">Gửi học phí</template>
              <el-menu-item index="4-sendApp">Gửi qua Onekids</el-menu-item>
              <el-menu-item index="4-sendSMS">Gửi SMS</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">Mẫu học phí</template>
              <el-menu-item index="5-uploadTemplateSchool">Upload File mẫu</el-menu-item>
              <el-menu-item index="5-deleteTemplateSchool">Xóa File mẫu</el-menu-item>
              <el-menu-item index="5-instructUploadFileSample">Hướng dẫn sử dụng</el-menu-item>
            </el-submenu>
            <el-menu-item index="showListForApp">Hiển thị</el-menu-item>
            <el-menu-item index="hiddenListForApp">Bỏ hiển thị</el-menu-item>
            <el-menu-item index="createNoteExcel">Tạo ghi chú</el-menu-item>
            <el-menu-item index="allCashier">Thanh toán hàng loạt</el-menu-item>
          </el-submenu>
        </el-menu>
        <el-button
            v-if="checkPermission(['fees_2_excel_export_template'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleOpenExportTempDialog"
        >
          Xuất mẫu học phí
        </el-button>

        <el-button
            v-if="checkPermission(['fees_2_excel_import'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleImportChecker"
        >
          Nhập từ Excel
        </el-button>
      </el-col>
    </el-row>


    <!--  dialog field  -->
    <ExportTemplateDialog
        :isOpenExportTemplateDialog="isOpenExportTemplateDialog"
        @closeExportTemplateDialog="handleCloseExportTemplateDialog"
    />
    <KidExcelImportChecker
        :isOpenImportChecker="isOpenExcelChecker"
        ref="checkDataBeforeUploadDialog"
        @closeCheckerDialog="handleCloseImportChecker"
        @refreshCollectionTurn="handleRefreshCollectionTurn"
    />

    <CreateNoteDialog
        :isOpenCreateNote="createNote"
        @closeNoteDialog="handleCloseNoteDialog"
    />
    <UploadTemplateSchool
        :gradeList="gradeList"
        :idClass="dataSearch.idClass.toString()"
        :isOpenUploadTemplateSchool="openUploadTemplateSchool"
        @close="handleCloseUploadTemplateSchool"
    />
    <SendAppDialog
        :dialog-visible-ex="dialogVisibleEx"
        @close="handleCloseSendApp"
        ref="SendAppDialog"
    />
    <delete-template-school-dialog :is-dialog-template-visible="isOpenDeleteTemplate"
                                   @closeTemplateDeleteDialog="handleCloseTemplateDeleteDialog"/>

    <AllCashier :isOpenAllCashierDialog="showAllCashierDialog"
                :bulkPayments.sync="bulkPayments"
                @dialog-close="closeAllCashierDialog"
                ref="AllCashier"></AllCashier>
    <!--    Thêm khoản thu-->
    <AddNameExpensesClass
        :isOpenAddNameExpensesDialog="showAddNameExpensesDialog"
        @close="closeAddNameExpensesDialog"
    />

    <!-- Cường thêm dialog chọn cách xuất excel-->
    <ExportTuitionFormDialog
        :isOpenExportTuitionFormDialog="isOpenExportTuitionFormDialog"
        @closeExportTuitionFormDialog="handleCloseExportTuitionFormDialog"
        :is-export-all-excel="isExportAllExcel"
        @update:monthAndYear="updateMonthAndYear"
    />
  </div>
</template>
<script>
import KidExcelImportChecker from "@/components/FinanceVer2/ByClass/TableSearchDialog/KidExcelImportChecker.vue";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import moment from "moment";
import GradeService from "@/services/GradeService";
import {mapState} from "vuex";
import ExportTemplateDialog from "@/components/FinanceVer2/ByClass/TableSearchDialog/ExportTemplateDialog.vue";
import CreateNoteDialog from "@/components/FinanceVer2/ByClass/ExcelTableDialog/CreateNoteDialog.vue";
import {v4} from "uuid";
import exportExcelMixin from "@/global/mixin/exportExcel.mixin";
import UploadTemplateSchool from "@/components/FinanceVer2/ByClass/TableSearchDialog/UploadTemplateSchool.vue";
import SendAppDialog from "@/components/FinanceVer2/ByClass/TableSearchDialog/SendAppDialog.vue";
import sendSMSMixin from "@/global/mixin/sendSMS.mixin";
import exportBillMixin from "@/global/mixin/exportBill.mixin";
import listOderForAppMixin from "@/global/mixin/listOderForApp.mixin";
import deleteOrderMixin from "@/global/mixin/deleteOrder.mixin";
import updateAttendanceDataMixin from "@/global/mixin/updateAttendanceData.mixin";
import checkPermission from "@/utils/permission";
import DeleteTemplateSchoolDialog
  from "@/components/FinanceVer2/ByClass/TableSearchDialog/DeleteTemplateSchoolDialog.vue";
import {kidStatusListExcel} from "@/constant/listCommon";
import AllCashier from "@/components/FinanceVer2/ByClass/ExcelTableDialog/AllCashier";
import AddNameExpensesClass from "@/components/FinanceVer2/ByClass/TableSearchDialog/AddNameExpensesClass.vue";
import ExportTuitionFormDialog from "@/components/FinanceVer2/ByClass/TableSearchDialog/ExportTuitionFormDialog";
import NotifyMessage from "@/commonFuncion/NotifyMessage";
import CommonFunction from "@/commonFuncion/CommonFunction";

export default {
  name: "KidsSearchTable",
  components: {
    AddNameExpensesClass,
    AllCashier,
    DeleteTemplateSchoolDialog,
    SendAppDialog,
    UploadTemplateSchool,
    CreateNoteDialog,
    ExportTemplateDialog,
    KidExcelImportChecker,
    ExportTuitionFormDialog
  },
  watch: {
    isOrderDelete: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    isOrderOpen: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    isOrderDestroy: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    // gọi nếu cần cập nhật trạng thái
    updateStatus: {
      handler() {
        this.isUpdateCollectionTurn = false
        this.getStatus();
      },
      deep: true,
    },
    monthAndYear: {
      handler(val) {
        this.dataSearch.month = val.split('-')[0]
        this.dataSearch.createdYear = val.split('-')[1]

      }
    },
  },
  computed: {
    ...mapState("finance2Module", [
      "totalPages",
      "idKidList",
      "listNullBillKids",
      "listHiddenOrderKids",
      "listShowForApp",
      "updateStatus",
      "messageShowForApp",
      "messageHiddenForApp",
      "messageHiddenForApp",
      "responseFilterList", "checkRevenue","checkResponse"
    ]),
  },
  mixins: [
    exportExcelMixin,
    sendSMSMixin,
    exportBillMixin,
    listOderForAppMixin,
    deleteOrderMixin,
    updateAttendanceDataMixin,
  ],
  created() {
    this.fetchDataTable();
    this.$store.commit("finance2Module/renew_bill_preview");
    this.createInvoice = false;
    this.dataSearch.monthAndYear = this.monthAndYear
  },
  data() {
    return {
      isUpdateCollectionTurn: true,
      monthAndYear: moment().format('M-yyyy'),
      // watcher
      dataInitialized: false, // sử dụng để ngăn watcher call api khởi tạo data
      // phân trang
      currentPagePagination: 1,
      // tên học sinh
      kidName: "",
      status: [],
      kidStatusListExcel,
      gradeList: [],
      classList: [],
      statusOrderList: [],
      flag: false,
      // giới hạn bản ghi
      sizeLimit: 30, // mặc định là  giới hạn 20 bản ghi
      listSizeLimit: [30, 40, 50],
      collectionTurn: 0,
      //Export Excel for month param
      previewExcelParam: {
        guiId: "success",
        date: "",
        idKidList: "",
        collectionTurn: 0,
      },
      dataSearch: {
        monthAndYear: "",
        collectionTurn: null, // đợt thu
        month: moment().format('M'),
        kidStatus: "STUDYING",
        idGrade: "",
        idClass: "",
        name: "", // tên học sinh
        page: 1,
        limit: 30,
        statusList: [],
        createdYear: moment().format("YYYY"),
      },
      // dialog field
      isOpenExportTemplateDialog: false,
      isOpenExportTuitionFormDialog: false,
      isOpenExportExcelByMonthDialog: false,
      isOpenExcelChecker: false,
      isOpenHistoryOrderKids: false,
      openUploadTemplateSchool: false,
      createNote: false,
      dialogVisibleEx: false,
      isOpenDeleteTemplate: false,
      showListForApp: {
        date: "",
        idKidList: "",
        collectionTurn: "",
      },
      exportExcelAll: {
        month: moment().format('M'),
        idGrade: "",
        idClass: "",
        year: moment().format("YYYY"),
        guiId: "",
        status: false,
        dateAttendance: ""
      },
      showAllCashierDialog: false,
      bulkPayments: false,
      showAddNameExpensesDialog: false,
      isExportAllExcel: false,
    };
  },
  // xoá dữ liệu khi back lại, f5 trang

  methods: {
    checkPermission,
    updateMonthAndYear(monthAndYear) {
      this.dataSearch.monthAndYear = monthAndYear;
    },
    // lấy lượt thu theo ngày
    handelUpdateDate() {
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.$store.commit("finance2Module/loading_data")
      this.$store.commit("finance2Module/setCheckRevenue")
      this.isUpdateCollectionTurn = true
      this.dataSearch.monthAndYear = this.monthAndYear
      this.getStatus();
    },
    // xử lý đổi trạng thái HS
    handleChangeKidStatus() {
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.$store.commit("finance2Module/loading_data")
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch)
    },
    // lấy lượt thu
    getCollectionTurn() {
      financeKidsV2Service
          .getCollectionTurn(
              this.dataSearch.month,
              this.dataSearch.idGrade,
              this.dataSearch.idClass,
              this.dataSearch.createdYear
          )
          .then((res) => {
            if (res.data === 0) {
              this.dataSearch.collectionTurn = null;
              this.collectionTurn = null;
              this.$store.commit("finance2Module/loading_data")
              this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
              return;
            }
            this.collectionTurn = res.data;
            this.dataSearch.collectionTurn = this.collectionTurn;
            this.$store.commit("finance2Module/loading_data")
            this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
          }).catch(() =>{
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("finance2Module/check_response",false)
      });
    },
    // bắt sự kiện tìm theo tên qua enter
    handleChangeKidName() {
      this.$store.commit("finance2Module/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },

    // Trả ra kết quả tìm kiếm theo tên khi bấm vào icon tìm kiếm
    handleSearchResultByName() {
      this.$store.commit("finance2Module/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    // tìm kiếm khi xóa tên học sinh
    handleClearKidName() {
      this.$store.commit("finance2Module/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    handleChangeCollectionTurn() {
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.$store.commit("finance2Module/loading_data")
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    /**
     Xử lý phân trang
     */
    handleSizeChange(value) {
      this.$store.commit("finance2Module/loading_data")
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.sizeLimit = value;
      this.dataSearch.limit = value;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    handleCurrentChange(value) {
      this.$store.commit("finance2Module/loading_data")
      this.currentPagePagination = value;
      this.dataSearch.page = value;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    // lấy danh sách trươc khi lấy ra table
    fetchDataTable() {
      this.getAllGrade(); // lấy danh sách lớp
    },

    /**
     * tìm tất cả các khối trong một trường
     */
    getAllGrade() {
      this.$store.commit("finance2Module/loading_data")
      GradeService.getGradeInPrinciple()
          .then((resp) => {
            this.gradeList = resp.data.data;
            if (this.gradeList.length > 0) {
              this.dataSearch.idGrade = this.gradeList[0].id; // cái này sẽ chạy api lấy getClassOfGrade
              this.getClassOfGrade();
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: "Lỗi dữ liệu!",
              type: "error",
              duration: 5000
            });
            this.$store.commit("finance2Module/check_response",false)
          });
    },

    /**
     * tìm tất cả lớp trong một khối
     */


    /**
     * thay đổi khối
     */
    handleChangeKidGrade() {
      this.$store.commit("finance2Module/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.isUpdateCollectionTurn = true; // cho phép gọi update đợt thu mới nhất
      this.getClassOfGrade();
    },
    getClassOfGrade() {
      financeKidsV2Service
          .getClassInGrade(this.dataSearch.idGrade)
          .then((resp) => {
            resp.data && resp.data.data ? this.classList = resp.data.data : this.classList = []
            this.classList.length > 0 ? this.dataSearch.idClass = this.classList[0].id : this.dataSearch.idClass = '' // data để hiển thị lên bảng
            this.getStatus();
          })
          .catch((err) => {
            this.$message({
              message: "Lỗi dữ liệu!",
              type: "error",
              duration: 5000
            });
            this.$store.commit("finance2Module/check_response",false)
            console.log(err);
          });
    },
    /**
     * Hiển thị tất cả trạng thái hóa đơn chưa đóng đóng đủ
     */

    handleChangeKidClass() {
      this.$store.commit("finance2Module/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.isUpdateCollectionTurn = true
      this.getStatus();
    },
    handleChangeStatus() {
      this.flag = false
      if (2 <= this.status.length) {
        for (let i = 0; i < this.status.length; i++) {
          if (this.status[i] === "Chưa có hóa đơn") {
            this.flag = true;
            break;
          }
        }
      }
      if (this.flag === false) {
        this.dataSearch.statusList = this.status
        this.$store.commit("finance2Module/loading_data");
        this.currentPagePagination = 1;
        this.dataSearch.page = 1;
        this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
      } else {
        this.$message.error({
          showClose: true,
          message: "Không thể chọn Chưa có hóa đơn cùng với các trạng thái khác. <br>" +
              "Vui lòng chọn lại!",
          dangerouslyUseHTMLString: true,
        });
      }
    },
    getStatus() {
      financeKidsV2Service
          .getListStatusExcel(
              this.dataSearch.month,
              this.dataSearch.idGrade,
              this.dataSearch.idClass,
              this.dataSearch.createdYear
          )
          .then((res) => {
            this.statusOrderList = res.data;
            if (!this.isUpdateCollectionTurn) {
              this.$store.dispatch(
                  "finance2Module/init_kids_data",
                  this.dataSearch
              );
              return;
            } else {
              this.getCollectionTurn();
            }
          }).catch((error) =>{
            this.$message({
              message: "Lỗi dữ liệu!",
              type: "error",
              duration: 5000
            });
            console.log(error)
            this.$store.commit("finance2Module/check_response",false)
      });
    },
    // mở dialog import
    handleImportChecker() {
      this.isOpenExcelChecker = !this.isOpenExcelChecker;

      let className = ""
      let gradeName = ""

      for (let i = 0; i < this.classList.length; i++) {
        if (this.dataSearch.idClass === this.classList[i].id) {
          className = this.classList[i].className
        }
      }

      for (let i = 0; i < this.gradeList.length; i++) {
        if (this.dataSearch.idGrade === this.gradeList[i].id) {
          gradeName = this.gradeList[i].gradeName
        }
      }
      this.$refs.checkDataBeforeUploadDialog.getCheckDataInitial(className, gradeName);
    },
    // đóng thẻ import
    handleCloseImportChecker(status) {
      this.isOpenExcelChecker = status;
    },
    // refresh lại đợt thu sau khi nhâpk
    handleRefreshCollectionTurn() {
      this.monthAndYear = this.dataSearch.monthAndYear
      this.handelUpdateDate()
    },
    handleCommand(command) {
      // gán giá trị
      const guiId = v4();
      const date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      const month = this.dataSearch.month;
      const idGrade = this.dataSearch.idGrade;
      const idClass = this.dataSearch.idClass;
      const idKidList = this.idKidList;
      const dataSearch = this.dataSearch;
      const showListForApp = this.showListForApp;
      if (command === '5-instructUploadFileSample') {
        this.instructUploadFileSample(guiId)
        return;
      }
      if (command === "5-uploadTemplateSchool") {
        this.openUploadTemplateSchool = true;
        return;
      }
      if (command === '5-deleteTemplateSchool') {
        this.isOpenDeleteTemplate = !this.isOpenDeleteTemplate
        return;
      }
      if (command === "2-exportAllBill") {
        this.exportBillAllByMonth(date, idGrade, idClass, guiId);
        return;
      }
      if (command === "3-exportAllExcel") {
        this.isOpenExportTuitionFormDialog = true;
        this.isExportAllExcel = true;
        return;
      }
      if (command === "updateAttendanceData") {
        this.updateAttendanceData(idGrade, idClass, month, this.dataSearch.createdYear, this.dataSearch)
        return;
      }
      if (this.idKidList === "") {
        this.$message.error({
          showClose: true,
          message: "Chưa có học sinh nào được chọn",
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.listHiddenOrderKids) {
        if (command === "2-openOrder") {
          this.openOrder(idKidList, dataSearch, showListForApp);
          return;
        }
        if (command === "2-destroyOrder") {
          this.destroyOrder(idKidList, dataSearch, showListForApp);
          return;
        }
        this.$message.error({
          showClose: true,
          message: `Học sinh:  ${this.listHiddenOrderKids} <br>Đã bị ẩn, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }
      if (this.dataSearch.collectionTurn !== null) {
        if (command === "3-exportExcel") {
          this.isOpenExportTuitionFormDialog = true;
          return;
        }
      }
      if (command === "2-createInvoice") {
        if (this.dataSearch.collectionTurn !== null) {
          const idKidListArray = this.idKidList.split(',').map(Number);
          let flag = false;
          for (let i = 0; i < idKidListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {
              if (idKidListArray[i] === this.responseFilterList[j].id && this.responseFilterList[j].status !== null) {
                flag = true;
                this.$message.error({
                  showClose: true,
                  message: `Chỉ thực hiện với học sinh chưa có hóa đơn!`,
                  dangerouslyUseHTMLString: true,
                  duration: 5000
                });
                return;
              }
            }
          }
          if (!flag) {
            const data = {
              idGrade: idGrade,
              idKidList: this.idKidList,
              date: date,
              collectionTurn: this.dataSearch.collectionTurn,
              idClass: this.dataSearch.idClass
            }
            financeKidsV2Service.createInvoice(data).then(() => {
              this.$notify.success({
                title: "Thông báo",
                message: "Khởi tạo khoản thu thành công",
              });
              this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
            }).catch((error) => {
              console.log(error)
            })
          }
          return;
        } else {
          this.$message.error({
            showClose: true,
            message: `Chỉ thực hiện với danh sách học sinh có đợt thu!`,
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
          return;
        }
      }
      if (this.listNullBillKids) {
        this.$message.error({
          showClose: true,
          message: `Học sinh:  ${this.listNullBillKids}<br>Chưa có khoản, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }

      // kiểm soát xem có trường hợp k thể xuất hóa đơn k

      switch (command) {
        case "2-pdfPreview": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month);
          this.previewExcelParam.idKidList = this.idKidList;
          this.previewExcelParam.collectionTurn =
              this.dataSearch.collectionTurn;
          this.$store.dispatch(
              "finance2Module/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfPreview'}
          );
          break;
        }
        case"2-pdfSplit": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month);
          this.previewExcelParam.idKidList = this.idKidList;
          this.previewExcelParam.collectionTurn =
              this.dataSearch.collectionTurn;
          this.$store.dispatch(
              "finance2Module/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfSplit'}
          );
          break;
        }
        case "showListForApp": {
          this.showListOrderForApp(idKidList, dataSearch, showListForApp);
          break;
        }

        case "hiddenListForApp": {
          this.hiddenListOrderForApp(idKidList, dataSearch, showListForApp);
          break;
        }

        case "2-deleteOrder": {
          this.deleteOrder(idKidList, dataSearch, showListForApp);
          break;
        }

        case "createNoteExcel": {
          this.createNoteMethod();
          break;
        }
        case "4-sendSMS": {
          this.sendSMS(idKidList, date, dataSearch.collectionTurn);
          break;
        }
        case "4-sendApp": {
          if (!this.idKidList) {
            this.$message.error({
              showClose: true,
              message: `Không có học sinh được chọn để hiển thị lên App`,
            });
            break;
          }
          if (this.messageHiddenForApp) {
            this.$message.error({
              showClose: true,
              message: `Danh sách học sinh chưa được hiển thị : <br>${this.messageHiddenForApp}`,
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
            break;
          }
          this.sendApp();
          break;
        }
        case "allCashier": {
          const idKidsListArray = this.idKidList.split(",").map(Number)
          let notActivatedList = this.responseFilterList.filter((x) => {
            return x.motherPhone === "" && x.fatherPhone === "";
          })

          let flag = false;
          let kid = null;
          if (notActivatedList.length > 0) {
            idKidsListArray.forEach((y) => {
              kid = notActivatedList.find((x) => x.id === y);
            });
          }
          if (kid) {
            this.$message.error({
              showClose: true,
              message: "Học sinh " + kid.fullName + " chưa được kích hoạt! Hãy kích hoạt hoặc bỏ khỏi danh sách",
              duration: 5000,
            });
            break;
          }

          for (let i = 0; i < idKidsListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {

              if (idKidsListArray[i] === this.responseFilterList[j].id) {
                if (this.responseFilterList[j].sumMoney === 0) {
                  this.$message.error({
                    showClose: true,
                    message: `Học sinh chưa có khoản thu không thực hiện được tác vụ`,
                    duration: 5000,
                  });
                  return;
                }
              }
              if (idKidsListArray[i] === this.responseFilterList[j].id) {
                if (this.responseFilterList[j].status !== "Chưa đóng 100%") {
                  flag = true;
                }
              }

            }
          }

          if (!flag) {
            this.openAllCashierDialog();
            break;
          } else {
            this.$message.error({
              showClose: true,
              message: `Chỉ thực hiện với học sinh chưa đóng 100%!`,
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
            break;
          }
        }
        default:
          break;
      }
    },
    // đóng thẻ import
    handleCloseOpenHistoryOrderKids(status) {
      this.isOpenHistoryOrderKids = status;
    },
    //Mở component HistoryOrderKidsCode
    handleOpenHistoryOrderKids() {
      this.isOpenHistoryOrderKids = !this.isOpenHistoryOrderKids;
    },
    // đóng thẻ import
    handleOpenExportTempDialog() {
      if (this.idKidList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có học sinh nào được chọn!",
          type: "error",
        });
      } else {
        this.isOpenExportTemplateDialog = true;
      }
    },

    handleCloseExportTemplateDialog(status) {
      this.isOpenExportTemplateDialog = status;
    },

    handleCloseExportTuitionFormDialog(status) {
      this.isOpenExportTuitionFormDialog = status;
    },
    // Gửi qua App mở dialog
    sendApp() {
      this.dialogVisibleEx = !this.dialogVisibleEx;
    },
    handleCloseTemplateDeleteDialog(value) {
      this.isOpenDeleteTemplate = value
    },
    /**
     * Mở dialog hiển thị tạo ghi chú
     */
    createNoteMethod() {
      if (this.idKidList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có học sinh nào được chọn!",
          type: "error",
        });
      } else {
        this.createNote = !this.createNote;
      }
    },
    handleCloseNoteDialog(close) {
      this.createNote = close;
    },
    handleCloseUploadTemplateSchool() {
      this.openUploadTemplateSchool = false;
    },
    handleCloseSendApp(close) {
      this.dialogVisibleEx = close;
    },
    /**
     * Mở Dialog thanh toán hàng loạt
     */
    openAllCashierDialog() {

      let month = this.dataSearch.month;
      let collectionTurn = this.dataSearch.collectionTurn;
      let year = this.dataSearch.createdYear;
      this.showAllCashierDialog = true;
      this.$refs.AllCashier.getDataAllCashierInitial(
          month,
          collectionTurn,
          this.idKidList,
          year
      );

    },
    closeAllCashierDialog() {
      this.showAllCashierDialog = false;
      this.$store.commit('finance2Module/get_status_excel')
    },
    // Mở dialog thêm khoản thu
    handleOpenAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = true
    },
    closeAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = false
    },
    //Quan trọng, ko đc xóa!
    xuatTheoDotThu(monthAndYear, selectedOption) {
      const guiId = v4();
      const date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      const idKidList = this.idKidList;
      const collectionTurn = this.dataSearch.collectionTurn;
      const dateAttendance = CommonFunction.convertDate(monthAndYear);
      const status = !selectedOption;

      if (collectionTurn != null) {

          this.handleExportExcelByMonth(guiId, date, idKidList, collectionTurn, status, dateAttendance);

      } else {
        NotifyMessage.notifyError("Không có dữ liệu học phí!")
      }
      this.isOpenExportTuitionFormDialog = false;

    },

    xuatTheoThang(monthAndYear, selectedOption) {
      const guiId = v4();
      this.exportExcelAll.month = this.dataSearch.month;
      this.exportExcelAll.guiId = guiId;
      this.exportExcelAll.idClass = this.dataSearch.idClass;
      this.exportExcelAll.idGrade = this.dataSearch.idGrade;
      this.exportExcelAll.year = this.dataSearch.createdYear;
      this.exportExcelAll.dateAttendance = CommonFunction.convertDate(monthAndYear);
      this.exportExcelAll.status = !selectedOption;
      this.exportExcelAllByMonth(this.exportExcelAll);
      this.isExportAllExcel = false;
      this.isOpenExportTuitionFormDialog = false;
    }
  },
};
</script>
<style lang="scss" scoped>

.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 36px;
  line-height: 35px;
  border-bottom: 0px solid transparent;
  color: #909399;
  background-color: #67c23a !important;
  border-radius: 4px;
  width: 109%;
}

/deep/ .el-submenu__title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

/deep/ .el-submenu__title i {
  color: #fff;
}

/deep/ .el-menu--popup-right-start {
  margin-left: 5px;
  margin-right: 5px;
}

/deep/ .el-menu--horizontal {
  border-right: none;
}

/deep/ .el-menu--horizontal {
  width: auto;
  border-right: none;
  border-radius: 4px;
}

.loading_title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: red;
  margin-left: auto;
  margin-right: 1.5rem;
}

/deep/ .el-loading-spinner .circular {
  height: 33px;
  width: 42px;
  -webkit-animation: loading-rotate 2s linear infinite;
  animation: loading-rotate 2s linear infinite;
}

.custom_button {
  font-family: Roboto, serif;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0.5rem;
}
</style>