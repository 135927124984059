var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-table" },
        [
          _c("el-date-picker", {
            staticClass: "custom-date-picker",
            staticStyle: { width: "115px" },
            attrs: {
              clearable: false,
              type: "month",
              placeholder: "Chọn tháng",
              "value-format": "M-yyyy",
              format: "MM-yyyy",
              "time-arrow-control": false,
            },
            on: { change: _vm.handelUpdateDate },
            model: {
              value: _vm.monthAndYear,
              callback: function ($$v) {
                _vm.monthAndYear = $$v
              },
              expression: "monthAndYear",
            },
          }),
          _c(
            "el-select",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "Trạng thái" },
              on: { change: _vm.handleChangeKidStatus },
              model: {
                value: _vm.dataSearch.kidStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "kidStatus", $$v)
                },
                expression: "dataSearch.kidStatus",
              },
            },
            _vm._l(_vm.kidStatusListExcel, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.key, label: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "input-grade",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "Chọn khối" },
              on: { change: _vm.handleChangeKidGrade },
              model: {
                value: _vm.dataSearch.idGrade,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idGrade", $$v)
                },
                expression: "dataSearch.idGrade",
              },
            },
            _vm._l(_vm.gradeList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.id, label: item.gradeName },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "160px" },
              attrs: { placeholder: "Chọn lớp" },
              on: { change: _vm.handleChangeKidClass },
              model: {
                value: _vm.dataSearch.idClass,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idClass", $$v)
                },
                expression: "dataSearch.idClass",
              },
            },
            _vm._l(_vm.classList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.className },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "203px" },
              attrs: {
                placeholder: "Chọn trạng thái",
                multiple: "",
                "collapse-tags": "",
                clearable: "",
              },
              on: {
                change: _vm.handleChangeStatus,
                "remove-tag": _vm.handleChangeStatus,
                clear: _vm.handleChangeStatus,
              },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            _vm._l(_vm.statusOrderList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticStyle: { width: "240px" },
              attrs: { clearable: "", placeholder: "Nhập tên, biệt danh" },
              on: { clear: _vm.handleClearKidName },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleChangeKidName.apply(null, arguments)
                },
              },
              model: {
                value: _vm.kidName,
                callback: function ($$v) {
                  _vm.kidName = $$v
                },
                expression: "kidName",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.handleSearchResultByName },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "100px" },
              attrs: { placeholder: "Đợt thu" },
              on: { change: _vm.handleChangeCollectionTurn },
              model: {
                value: _vm.dataSearch.collectionTurn,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "collectionTurn", $$v)
                },
                expression: "dataSearch.collectionTurn",
              },
            },
            _vm._l(_vm.collectionTurn, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { value: item, label: `Đợt ${item}` },
              })
            }),
            1
          ),
          _vm.checkRevenue
            ? _c(
                "el-button",
                {
                  staticClass: "custom_button",
                  staticStyle: { float: "right" },
                  attrs: {
                    type: "success",
                    icon: "el-icon-plus",
                    size: "medium",
                  },
                  on: { click: _vm.handleOpenAddNameExpensesDialog },
                },
                [_vm._v("\n      Thêm khoản\n    ")]
              )
            : _vm._e(),
          _vm.loadingBill === true
            ? _c(
                "span",
                {
                  staticClass: "loading_title",
                  staticStyle: { color: "red", "text-align": "right" },
                },
                [
                  _vm._v("Dữ liệu đang được xử lý... "),
                  _c("span", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingBill,
                        expression: "loadingBill",
                      },
                    ],
                  }),
                ]
              )
            : _vm._e(),
          _vm.loadingUpdate === true
            ? _c("span", { staticClass: "loading_title" }, [
                _vm._v("Đang cập nhật điểm danh... "),
                _c("span", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingUpdate,
                      expression: "loadingUpdate",
                    },
                  ],
                }),
              ])
            : _vm._e(),
          _vm.bulkPayments === true
            ? _c("span", { staticClass: "loading_title" }, [
                _vm._v("Đang cập nhật thanh toán toàn bộ... "),
                _c("span", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.bulkPayments,
                      expression: "bulkPayments",
                    },
                  ],
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._t("default"),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                display: "inline-flex",
                "justify-content": "right",
              },
              attrs: { span: 20, offset: 4 },
            },
            [
              _c("el-pagination", {
                staticStyle: {
                  "margin-right": "5.2rem",
                  "margin-left": "auto",
                },
                attrs: {
                  "current-page": _vm.currentPagePagination,
                  "page-sizes": _vm.listSizeLimit,
                  "page-size": _vm.sizeLimit,
                  layout: " prev, pager,sizes, next",
                  total: _vm.sizeLimit * _vm.totalPages,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPagePagination = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPagePagination = $event
                  },
                },
              }),
              _vm.checkPermission(["fees_2_excel_operation"])
                ? _c(
                    "el-menu",
                    {
                      staticStyle: { "margin-right": "1.2rem" },
                      attrs: {
                        "background-color": "#67C23A",
                        "text-color": "#FFFFFF",
                        "active-text-color": "#FFFFFF",
                        "unique-opened": true,
                        "menu-trigger": "hover",
                        mode: "horizontal",
                      },
                      on: { select: _vm.handleCommand },
                    },
                    [
                      _c(
                        "el-submenu",
                        { attrs: { index: "1" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Tác vụ")]),
                          _vm.updateAttendance === true
                            ? _c(
                                "el-menu-item",
                                { attrs: { index: "updateAttendanceData" } },
                                [_vm._v("Cập nhật điểm danh\n          ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-submenu",
                            { attrs: { index: "2" } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Hóa đơn"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2-createInvoice" } },
                                [_vm._v("Khởi tạo khoản thu")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2-pdfPreview" } },
                                [_vm._v("In hóa đơn 1 liên")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2-pdfSplit" } },
                                [_vm._v("In hóa đơn 2 liên")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2-exportAllBill" } },
                                [
                                  _vm._v(
                                    "Xuất hóa đơn theo tháng\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2-deleteOrder" } },
                                [_vm._v("Hủy hóa đơn")]
                              ),
                              _vm.listHiddenOrderKids
                                ? _c(
                                    "el-menu-item",
                                    { attrs: { index: "2-openOrder" } },
                                    [_vm._v("Khôi phục hóa đơn")]
                                  )
                                : _vm._e(),
                              _vm.listHiddenOrderKids
                                ? _c(
                                    "el-menu-item",
                                    { attrs: { index: "2-destroyOrder" } },
                                    [_vm._v("Xóa hóa đơn")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-submenu",
                            { attrs: { index: "3" } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Xuất học phí tổng hợp"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "3-exportExcel" } },
                                [_vm._v("Theo đợt đã chọn")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "3-exportAllExcel" } },
                                [_vm._v("Theo tất cả các đợt")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-submenu",
                            { attrs: { index: "4" } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Gửi học phí"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "4-sendApp" } },
                                [_vm._v("Gửi qua Onekids")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "4-sendSMS" } },
                                [_vm._v("Gửi SMS")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-submenu",
                            { attrs: { index: "5" } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Mẫu học phí"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "5-uploadTemplateSchool" } },
                                [_vm._v("Upload File mẫu")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "5-deleteTemplateSchool" } },
                                [_vm._v("Xóa File mẫu")]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: {
                                    index: "5-instructUploadFileSample",
                                  },
                                },
                                [_vm._v("Hướng dẫn sử dụng")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "showListForApp" } },
                            [_vm._v("Hiển thị")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "hiddenListForApp" } },
                            [_vm._v("Bỏ hiển thị")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "createNoteExcel" } },
                            [_vm._v("Tạo ghi chú")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "allCashier" } },
                            [_vm._v("Thanh toán hàng loạt")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkPermission(["fees_2_excel_export_template"])
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "font-family": "Roboto,serif" },
                      attrs: {
                        type: "success",
                        icon: "el-icon-link",
                        size: "medium",
                      },
                      on: { click: _vm.handleOpenExportTempDialog },
                    },
                    [_vm._v("\n        Xuất mẫu học phí\n      ")]
                  )
                : _vm._e(),
              _vm.checkPermission(["fees_2_excel_import"])
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "font-family": "Roboto,serif" },
                      attrs: {
                        type: "success",
                        icon: "el-icon-link",
                        size: "medium",
                      },
                      on: { click: _vm.handleImportChecker },
                    },
                    [_vm._v("\n        Nhập từ Excel\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExportTemplateDialog", {
        attrs: { isOpenExportTemplateDialog: _vm.isOpenExportTemplateDialog },
        on: { closeExportTemplateDialog: _vm.handleCloseExportTemplateDialog },
      }),
      _c("KidExcelImportChecker", {
        ref: "checkDataBeforeUploadDialog",
        attrs: { isOpenImportChecker: _vm.isOpenExcelChecker },
        on: {
          closeCheckerDialog: _vm.handleCloseImportChecker,
          refreshCollectionTurn: _vm.handleRefreshCollectionTurn,
        },
      }),
      _c("CreateNoteDialog", {
        attrs: { isOpenCreateNote: _vm.createNote },
        on: { closeNoteDialog: _vm.handleCloseNoteDialog },
      }),
      _c("UploadTemplateSchool", {
        attrs: {
          gradeList: _vm.gradeList,
          idClass: _vm.dataSearch.idClass.toString(),
          isOpenUploadTemplateSchool: _vm.openUploadTemplateSchool,
        },
        on: { close: _vm.handleCloseUploadTemplateSchool },
      }),
      _c("SendAppDialog", {
        ref: "SendAppDialog",
        attrs: { "dialog-visible-ex": _vm.dialogVisibleEx },
        on: { close: _vm.handleCloseSendApp },
      }),
      _c("delete-template-school-dialog", {
        attrs: { "is-dialog-template-visible": _vm.isOpenDeleteTemplate },
        on: { closeTemplateDeleteDialog: _vm.handleCloseTemplateDeleteDialog },
      }),
      _c("AllCashier", {
        ref: "AllCashier",
        attrs: {
          isOpenAllCashierDialog: _vm.showAllCashierDialog,
          bulkPayments: _vm.bulkPayments,
        },
        on: {
          "update:bulkPayments": function ($event) {
            _vm.bulkPayments = $event
          },
          "update:bulk-payments": function ($event) {
            _vm.bulkPayments = $event
          },
          "dialog-close": _vm.closeAllCashierDialog,
        },
      }),
      _c("AddNameExpensesClass", {
        attrs: { isOpenAddNameExpensesDialog: _vm.showAddNameExpensesDialog },
        on: { close: _vm.closeAddNameExpensesDialog },
      }),
      _c("ExportTuitionFormDialog", {
        attrs: {
          isOpenExportTuitionFormDialog: _vm.isOpenExportTuitionFormDialog,
          "is-export-all-excel": _vm.isExportAllExcel,
        },
        on: {
          closeExportTuitionFormDialog: _vm.handleCloseExportTuitionFormDialog,
          "update:monthAndYear": _vm.updateMonthAndYear,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }