import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";

export default {
  data(){
    return{
      isOrderDelete:false
    }
  },
  methods: {
    // Hủy hóa đơn
    deleteOrder(idKidList, dataSearch, showListForApp) {
      if (idKidList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có học sinh nào được chọn",
          type: "error",
        });
      } else {
        this.$confirm("Bạn có chắc chắn muốn hủy hóa đơn?", "Thông báo !", {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }).then(() => {
          showListForApp.date = this.$funcCommon.formatMY(dataSearch.createdYear, dataSearch.month);
          showListForApp.idKidList = idKidList.split(",").map(Number);
          showListForApp.collectionTurn = dataSearch.collectionTurn;

          financeKidsV2Service
            .deleteOrderKidsExcel(showListForApp)
            .then(() => {
              this.$message({
                message: "Hủy hóa đơn thành công",
                type: "success",
              });
              this.isOrderDelete=!this.isOrderDelete
            })
              .then(()=>{ this.$store.dispatch("finance2Module/init_kids_data", dataSearch);})
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
        });
      }
    },
  },
};