<template>
  <el-dialog
      width="700px"
      top="50px"
      :visible.sync="isOpenKidDetail"
      :before-close="handleClose"
      @click="handleOutsideClick"
      :close-on-click-modal="false"
  >
    <div>
      <h2>Thông tin bổ sung</h2>
    </div>
    <h4>
      <el-row>
        <el-col :span="12">Tiêu đề</el-col>
        <el-col :span="4">{{ "Tháng " + dataSearch.month }}</el-col>
        <el-col :span="4">{{ monthBefore }}</el-col>
        <el-col :span="4">Excel</el-col>
      </el-row>
    </h4>

    <hr/>
    <el-row>
      <el-col :span="12">Học T2-T6</el-col>
      <el-col :span="4">{{ updateFields.arriveT2t6Total }}</el-col>
      <el-col :span="4">{{ updateFields.arriveT2t6TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.arriveT2t6 }}</el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.arriveT2t6"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>

    <el-row>
      <el-col :span="12">Học T7</el-col>
      <el-col :span="4">{{ updateFields.arriveT7Total }}</el-col>
      <el-col :span="4">{{ updateFields.arriveT7TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.arriveT7 }}</el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.arriveT7"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Học CN</el-col>
      <el-col :span="4">{{ updateFields.arriveCnTotal }}</el-col>
      <el-col :span="4">{{ updateFields.arriveCnTotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.arriveCn }}</el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.arriveCn"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ có phép (T2-T6)</el-col>
      <el-col :span="4">{{ updateFields.absentCpT2t6Total }}</el-col>
      <el-col :span="4">{{ updateFields.absentCpT2t6TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.absentCpT2t6 }}
      </el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentCpT2t6"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ không phép (T2-T6)</el-col>
      <el-col :span="4">{{ updateFields.absentKpT2t6Total }}</el-col>
      <el-col :span="4">{{ updateFields.absentKpT2t6TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.absentKpT2t6 }}
      </el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentKpT2t6"
        >
        </el-input>
      </el-col>
    </el-row>

    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ có phép (T7)</el-col>
      <el-col :span="4">{{ updateFields.absentCpT7Total }}</el-col>
      <el-col :span="4">{{ updateFields.absentCpT7TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.absentCpT7 }}
      </el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentCpT7"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ không phép (T7)</el-col>
      <el-col :span="4">{{ updateFields.absentKpT7Total }}</el-col>
      <el-col :span="4">{{ updateFields.absentKpT7TotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.absentKpT7 }}
      </el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentKpT7"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Đón muộn</el-col>
      <el-col :span="4">{{ updateFields.leaveLaterTotal }}</el-col>
      <el-col :span="4">{{ updateFields.leaveLaterTotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.leaveLater }}</el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.leaveLater"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Số ngày theo lịch hàng tháng</el-col>
      <el-col :span="4">{{ updateFields.quantityDateTotal }}</el-col>
      <el-col :span="4">{{ updateFields.quantityDateTotalMonthBefore }}</el-col>
      <el-col :span="4" v-if="!isUpdate">{{ updateFields.quantityDate }}
      </el-col>
      <el-col :span="3" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityDate"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">(1) Ghi chú nhà trường:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.inputNotice1 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini" placeholder="Nhập tại đây" v-model="updateFields.inputNotice1" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="18">(2) Ghi chú trên hóa đơn:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.inputNotice2 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.inputNotice2" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">(3) Ghi chú khác:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.inputNotice3 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.inputNotice3" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">Mô tả đợt thu :</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.descriptionTurn }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.descriptionTurn" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button size="medium" type="primary" @click="saveInfoAttendance" v-show="isEditing">Cập nhật</el-button>
      <el-button size="medium" type="success" @click="handleUpdate" v-show="!isEditing"
                 v-if="this.statusOrder !== 'Ẩn hóa đơn'">Sửa
      </el-button>
      <el-button size="medium" type="danger" @click="handleClose"><i class="el-icon-circle-close"/> Đóng</el-button>
    </div>
  </el-dialog>
</template>
<script>
import FinanceKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {mapState} from "vuex";

export default {
  name: "ExcelKidDetail",

  computed: {
    ...mapState("finance2Module", ["dataSearch"]),
    formattedNote() {
      // Thay thế ký tự "/n" bằng thẻ <br> để tạo các dòng mới
      return this.updateFields.inputNotice2.replace('/n', '<br>');
    },
  },

  props: {
    kidsDetailData: {
      type: Object,
      default: () => {
      },
    },
    isOpenKidDetail: {
      type: Boolean,
      default: () => false,
    },
  },


  data() {
    return {
      isUpdate: false,
      isEditing: false,
      updateFields: {
        arriveT2t6: 0,
        arriveT7: 0,
        arriveCn: 0,
        absentCpT2t6: 0,
        absentKpT2t6: 0,
        absentCpT7: 0,
        absentKpT7: 0,
        leaveLater: 0,
        quantityDate: 0,
        inputNotice1: "",
        inputNotice2: "",
        inputNotice3: "",
        arriveT2t6Total: 0,
        arriveT7Total: 0,
        arriveCnTotal: 0,
        absentCpT2t6Total: 0,
        absentKpT2t6Total: 0,
        absentCpT7Total: 0,
        absentKpT7Total: 0,
        leaveLaterTotal: 0,
        quantityDateTotal: 0,
        descriptionTurn: '',

        arriveT2t6TotalMonthBefore: 0,
        arriveT7TotalMonthBefore: 0,
        arriveCnTotalMonthBefore: 0,
        absentCpT2t6TotalMonthBefore: 0,
        absentKpT2t6TotalMonthBefore: 0,
        absentCpT7TotalMonthBefore: 0,
        absentKpT7TotalMonthBefore: 0,
        leaveLaterTotalMonthBefore: 0,
        quantityDateTotalMonthBefore: 0,
      },
      dataResp: {
        date: "",
        idKid: Number,
        collectionTurn: Number,
        arriveT2t6: Number,
        arriveT7: Number,
        arriveCn: Number,
        absentCpT2t6: Number,
        absentKpT2t6: Number,
        absentCpT7: Number,
        absentKpT7: Number,
        leaveLater: Number,
        quantityDate: Number,
        noteExcel1: "",
        noteExcel2: "",
        noteExcel3: "",
        descriptionTurn: "",
      },
      idKid: Number,
      collectionTurn: Number,
      statusOrder: "",
      monthCurrent: "",
      monthBefore: "",
    };

  },
  methods: {


    getInfoByMonthAndTurn(month, idKid, collectionTurn, statusOrder, createdYear) {

      /**
       * Tháng trước
       */
      if (month == 1) {
        this.monthBefore = "Tháng 12/" + (parseInt(this.dataSearch.createdYear) - 1);
      } else {
        this.monthBefore = "Tháng " + (month - 1);
      }


      this.statusOrder = statusOrder;
      this.idKid = idKid;
      this.collectionTurn = collectionTurn;
      FinanceKidsV2Service.getKidExcelInfoByMonthAndTurn(month, idKid, collectionTurn, createdYear).then((resp) => {
        this.updateFields.arriveT2t6 = resp.data.arriveT2t6;
        this.updateFields.arriveT7 = resp.data.arriveT7;
        this.updateFields.arriveCn = resp.data.arriveCn;
        this.updateFields.absentCpT2t6 = resp.data.absentCpT2t6;
        this.updateFields.absentKpT2t6 = resp.data.absentKpT2t6;
        this.updateFields.absentCpT7 = resp.data.absentCpT7;
        this.updateFields.absentKpT7 = resp.data.absentKpT7;
        this.updateFields.leaveLater = resp.data.leaveLater;
        this.updateFields.quantityDate = resp.data.quantityDate;
        this.updateFields.inputNotice1 = resp.data.noteExcel1;
        this.updateFields.inputNotice2 = resp.data.noteExcel2;
        this.updateFields.inputNotice3 = resp.data.noteExcel3;
        this.updateFields.arriveT2t6Total = resp.data.arriveT2t6Total;
        this.updateFields.arriveT7Total = resp.data.arriveT7Total;
        this.updateFields.arriveCnTotal = resp.data.arriveCnTotal;
        this.updateFields.absentCpT2t6Total = resp.data.absentCpT2t6Total;
        this.updateFields.absentKpT2t6Total = resp.data.absentKpT2t6Total;
        this.updateFields.absentCpT7Total = resp.data.absentCpT7Total;
        this.updateFields.absentKpT7Total = resp.data.absentKpT7Total;
        this.updateFields.leaveLaterTotal = resp.data.leaveLaterTotal;
        this.updateFields.quantityDateTotal = resp.data.quantityDateTotal;
        this.updateFields.descriptionTurn = resp.data.descriptionTurn;


        this.updateFields.arriveT2t6TotalMonthBefore = resp.data.arriveT2t6TotalMonthBefore;
        this.updateFields.arriveT7TotalMonthBefore = resp.data.arriveT7TotalMonthBefore;
        this.updateFields.arriveCnTotalMonthBefore = resp.data.arriveCnTotalMonthBefore;
        this.updateFields.absentCpT2t6TotalMonthBefore = resp.data.absentCpT2t6TotalMonthBefore;
        this.updateFields.absentKpT2t6TotalMonthBefore = resp.data.absentKpT2t6TotalMonthBefore;
        this.updateFields.absentCpT7TotalMonthBefore = resp.data.absentCpT7TotalMonthBefore;
        this.updateFields.absentKpT7TotalMonthBefore = resp.data.absentKpT7TotalMonthBefore;
        this.updateFields.leaveLaterTotalMonthBefore = resp.data.leaveLaterTotalMonthBefore;
        this.updateFields.quantityDateTotalMonthBefore = resp.data.quantityDateTotalMonthBefore;


      })
    },

    saveInfoAttendance() {
      this.dataResp.date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      this.dataResp.idKid = this.idKid;
      this.dataResp.collectionTurn = this.collectionTurn;
      this.dataResp.arriveT2t6 = this.updateFields.arriveT2t6;
      this.dataResp.arriveT7 = this.updateFields.arriveT7;
      this.dataResp.arriveCn = this.updateFields.arriveCn;
      this.dataResp.absentCpT2t6 = this.updateFields.absentCpT2t6;
      this.dataResp.absentKpT2t6 = this.updateFields.absentKpT2t6;
      this.dataResp.absentCpT7 = this.updateFields.absentCpT7;
      this.dataResp.absentKpT7 = this.updateFields.absentKpT7;
      this.dataResp.leaveLater = this.updateFields.leaveLater;
      this.dataResp.quantityDate = this.updateFields.quantityDate;
      this.dataResp.noteExcel1 = this.updateFields.inputNotice1;
      this.dataResp.noteExcel2 = this.updateFields.inputNotice2;
      this.dataResp.noteExcel3 = this.updateFields.inputNotice3;
      this.dataResp.descriptionTurn = this.updateFields.descriptionTurn;
      this.$confirm("Bạn có chắc chắn muốn lưu thay đổi ?", "Thông báo !", {
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        confirmButtonText: "Có",
        cancelButtonText: "Không",
      }).then(() => {
        FinanceKidsV2Service.saveInfoAttendanceKid(this.dataResp).then(() => {
          this.$message({
            message: "Cập nhật thành công",
            type: "success",
          });
          this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
        })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
      });
      this.handleClose();
    },

    handleClose() {
      this.isEditing = false;
      this.isUpdate = false;
      this.$emit("closeDetailKid", false);
    },
    handleUpdate() {
      this.isEditing = true;
      this.isUpdate = !this.isUpdate;
    },
    // Kiểm tra xem sự kiện bấm chuột có xảy ra trong dialog hay không
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isUpdate = false;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input--mini .el-input__inner {
  font-size: 14px;
  font-family: Roboto, serif;
  height: 15px;
  line-height: 28px;
  border: none;
  padding-left: 0px;
  color: #3a3ace;
}

h2 {
  padding-bottom: 10px;
  margin-top: 0px;
}
</style>
