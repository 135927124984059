<template>
  <el-dialog @open="handleFetchTemplate" :visible.sync="isDialogTemplateVisible" top="30vh" width="530px" :close-on-click-modal="false"
             title="Xóa mẫu học phí" :before-close="handleClose">
    <el-row style="height: 90px">
      <el-col :span="9" style="margin-top: 5px">Danh sách mẫu học phí :</el-col>
      <el-col :span="15">
        <el-select v-model="listTemplate" @change="handleSelectChange" multiple size="small" style="width: 255px" placeholder="Chọn file">
          <el-option
              v-for="(item,index) in listTemplateSchool"
              :key="index"
              :label="item.nameFile"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
          <span v-if="listTemplate.length!==0">Mô tả file: {{selectedDescription}} </span>
      </el-col>
    </el-row>
    <div class="custom_button">
      <el-button type="success" @click="handleDeleteTemplate" icon="el-icon-delete" size="medium" v-if="listTemplate.length !== 0">Xóa File</el-button>
      <el-button type="danger" @click="handleClose" size="medium" > <i class="el-icon-circle-close"/> Đóng</el-button>
    </div>
  </el-dialog>
</template>
<script>
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {mapState} from "vuex";

export default {
  name: 'DeleteTemplateSchoolDialog',
  props: {
    isDialogTemplateVisible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      selectedDescription:'',
      listTemplate:[],
      params: {
        listTemplate: '',
        idClass: ''
      },
      listTemplateSchool: []
    }
  },
  computed: {
    ...mapState("finance2Module", ["idKidList", "dataSearch"]),
  },
  methods: {
    handleSelectChange(){
      const selectedFile=this.listTemplateSchool.filter(file=>this.listTemplate.includes(file.id))
      const foundSelectedFile=selectedFile.map(item => item.description);
       this.selectedDescription= foundSelectedFile.join(', ')
    },
    handleDeleteTemplate() {
      this.$confirm('Thao tác này sẽ xóa file hệ thống. Tiếp tục?', 'Warning', {
        title:'Cảnh báo',
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        this.params.listTemplate=this.listTemplate.join(',')
        this.params.idClass = this.dataSearch.idClass
        financeKidsV2Service.deleteTemplateSchool(this.params).then(() => {
          this.$message({
            type: 'success',
            message: 'Xóa thành công'
          })
          this.handleClose() // tắt màn
          ;
        }).catch(() => {
          this.$message({
            message: "Xóa file thất bại",
            type: "error",
          });
        });
      }).catch(() => {
        this.$message({
          type: "error",
          message: 'Xóa không thành công'
        });
      });
    },
    handleClose() {
      this.listTemplateSchool =[]
      this.listTemplate = []
      this.selectedDescription = ''
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
      this.$emit('closeTemplateDeleteDialog', false)
    },
    handleFetchTemplate() {
      financeKidsV2Service
          .listFileSchoolTemplate(this.dataSearch.idClass, this.dataSearch.createdYear)
          .then((res) => {
            this.listTemplateSchool = res.data.filter(item=>item.id!==0)
          });
    },
  }
}
</script>
<style lang="scss" scoped>
.el-button--danger {
  margin-right: 11%;
  color: #FFF;
  background-color: #F56C6C;
  border-color: #F56C6C;
}

.custom_button{
  display: flex;
  justify-content: right;
}
</style>