<template>
  <div class="dialog-container">
    <el-dialog
        width="700px"
        title="Upload file mẫu Excel học phí"
        :visible.sync="isOpenUploadTemplateSchool"
        :before-close="handleCloseTemplateSchool"
        :close-on-click-modal="false"
        @open="handleOpen"
    >
      <div class="upload-section">
        <el-form
            :model="formData"
            label-position="left"
            :rules="rules"
            ref="formData"
        >
          <div class="file-description">
            <el-form-item style="margin-top: 20px">
              <el-radio-group v-model="selectClass" @change="selectClassChange">
                <el-radio label="one class">Lớp hiện tại</el-radio>
                <el-radio label="many class">Chọn nhiều lớp</el-radio>
                <el-radio label="all class">Chọn tất cả lớp trong trường</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="showSelectClasses === true">
              <!-- chọn khối -->
              <el-select
                  @change="handleChangeKidGrade"
                  class=" input-grade"
                  style="width: 200px"
                  v-model="selectClasses.idGrade"
                  placeholder="Chọn khối"
              >
                <el-option
                    v-for="(item,index) in gradeListUpdateTemplate"
                    :key="index"
                    :value="item.id"
                    :label="item.gradeName"
                ></el-option>
              </el-select>

              <!-- chọn lớp -->
              <el-select
                  @visible-change="handleChangeKidClass"
                  @remove-tag="handleChangeKidClass"
                  @clear="handleChangeKidClass"
                  multiple
                  collapse-tags
                  clearable
                  style="width: 200px; margin-left: 10px"
                  v-model="idClassList"
                  placeholder="Chọn lớp"
              >
                <el-option
                    v-for="item in classListUpdateTemplate"
                    :key="item.id"
                    :value="item.id"
                    :label="item.className"
                >
                </el-option>
              </el-select>
              <span v-if="this.idClassList.length === 0"
                    style="margin-left: 10px; color: red">* Vui lòng chọn lớp!</span>
            </el-form-item>
            <el-form-item label="Nhập mô tả" prop="sendDescription">
              <el-input
                  type="textarea"
                  :rows="2"
                  v-model="formData.sendDescription"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="file-upload-field">
              <el-upload
                  action="#"
                  :auto-upload="false"
                  :on-change="toggleUpload"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  ref="upload"
                  accept=".xls,.xlsx"
                  :file-list="formData.fileList"
              >
                <el-button size="small" type="primary" style="font-size: 15px">Upload file <i
                    class="el-icon-upload el-icon-right"></i></el-button>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <div class="buttons">
          <el-button
              :loading="loadingUploadTemplate"
              size="small"
              type="success"
              style="margin-right: 10px"
              @click="handleUpload()"
              v-if="this.checkButton === true"
          >
            Tải lên
          </el-button>
          <el-button size="small" type="danger" @click="handleCloseTemplateSchool">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  data() {
    return {
      showSelectClasses: false,
      selectClass: "one class",
      selectClasses: {
        idGrade: 0,
      },
      gradeListUpdateTemplate: [],
      classListUpdateTemplate: [],
      idClassList: [],
      loadingUploadTemplate: false,
      selectedFile: null,
      showUploadButton: false,
      checkButton: false,
      year: moment().format("YYYY"),
      formData: {
        sendDescription: "",
        fileList: [],
      },
      rules: {
        sendDescription: [
          {
            required: true,
            message: "Mô tả không được để trống!",
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    idClass: {
      type: String,
    },
    gradeList: {
      type: Array,
      default: () => []
    },
    isOpenUploadTemplateSchool: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    idClassList() {
      if (this.idClassList.length === 0) {
        this.checkButton = false;
      }
      this.checkShowButton()
    },
    'formData.sendDescription': function () {
      if (this.formData.sendDescription === '') {
        this.checkButton = false;
      }
      this.checkShowButton()
    },
    'formData.fileList': function () {
      if (this.formData.fileList.length === 0) {
        this.checkButton = false;
      }
      this.checkShowButton()
    }
  },
  computed: {
    ...mapState('finance2Module', ['dataSearch'])
  },
  methods: {
    handleOpen(){
      this.selectClassChange()
    },
    handleRemove(file, fileList) {
      this.showUploadButton = fileList.length === 0;
      this.checkShowButton()
    },
    handlePreview() {
      this.checkShowButton()
    },
    beforeRemove(file) {
      return new Promise((resolve, reject) => {
        this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`)
            .then(() => {
              this.showUploadButton = false;
              this.$refs.upload.clearFiles();
              this.checkShowButton()
            })
            .catch(() => {
              reject();
            });
      });
    },

    // Tải template
    async handleUpload() {
      this.loadingUploadTemplate = true;
      try {
        if (!this.formData.fileList || this.formData.fileList.length === 0) {
          console.error("Không có file được chọn");
          this.loadingUploadTemplate = false;
          return;
        }
        const formData = new FormData();
        formData.append("idClassList", this.idClassList);
        formData.append("description", this.formData.sendDescription);
        formData.append("year", this.dataSearch.createdYear);
        for (const file of this.formData.fileList) {
          formData.append("nameFile", file.raw);
        }
        await financeKidsV2Service.uploadFileTemplateSchool(formData).then((res) => {
          if (res.data.message !== "Tải file thất bại") {
            this.$notify.success({
              title: "Thông báo",
              message: "Upload dữ liệu thành công",
            });
            this.loadingUploadTemplate = false;
          } else {
            this.$message({
              message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
              type: "error",
            });
            this.loadingUploadTemplate = false;
          }
        })
        this.loadingUploadTemplate = false;
        this.showUploadButton = false;
        this.$refs.upload.clearFiles();
        this.handleCloseTemplateSchool();
      } catch (error) {
        this.$message({
          message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
          type: "error",
        });
        this.loadingUploadTemplate = false;
      }
    },

    toggleUpload(file, fileList) {
      this.formData.fileList = fileList.slice(-1);
      this.showUploadButton = fileList.length > 0;
      this.checkShowButton();
    },

    // Lấy danh sách lớp
    handleChangeKidGrade() {
      financeKidsV2Service
          .getClassInGrade(this.selectClasses.idGrade)
          .then((resp) => {
            this.classListUpdateTemplate = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      this.checkShowButton()
    },

    // Chọn radio
    selectClassChange() {
      this.idClassList = []
      if (this.selectClass === "one class") {
        this.showSelectClasses = false;
        this.idClassList.push(parseInt(this.dataSearch.idClass))
      } else if (this.selectClass === "many class") {
        this.showSelectClasses = true;
        this.gradeListUpdateTemplate = this.$props.gradeList;
        this.selectClasses.idGrade = this.gradeListUpdateTemplate[0].id;
        this.handleChangeKidGrade()
      } else if (this.selectClass === "all class") {
        this.showSelectClasses = false;
        financeKidsV2Service.getAllClassToSchool().then((response) => {
          this.idClassList = response.data;
        })
      }
      this.checkShowButton()
    },

    // Chọn lớp
    handleChangeKidClass() {
      this.checkShowButton()
    },

    // ẩn/hiện Tải lên
    checkShowButton() {
      this.checkButton = false;
      if (this.selectClass === "many class") {
        if (this.idClassList.length !== 0 && this.showUploadButton === true && this.formData.sendDescription !== '') {
          return this.checkButton = true;
        } else return this.checkButton = false;
      } else {
        if (this.showUploadButton === true && this.formData.sendDescription !== '') {
          return this.checkButton = true;
        } else return this.checkButton = false;
      }
    },

    // Đóng dialog
    handleCloseTemplateSchool() {
      this.clearForm()
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
      this.$emit("close", false);
    },

    //   Reset form
    clearForm() {
      this.checkButton = false;
      this.idClassList = [];
      this.showSelectClasses = false;
      this.selectClass = 'one class';
      this.showUploadButton = false;
      this.loadingUploadTemplate = false;
      this.$refs.formData.resetFields()
      this.$refs.upload.clearFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-upload-label {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.file-upload-field {
  display: flex;
  justify-content: left;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}

.buttons button {
  font-size: 15px;
}

</style>
