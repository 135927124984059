<template>
  <div class="dialog-container">
    <el-dialog
        width="600px"
        title="Thay đổi tên khoản thu"
        :visible.sync="isOpenChangeNameRevenusDialog"
        :before-close="handleCloseChangeNameRevenusDialog"
        :close-on-click-modal="false"
    >
      <div>
        <el-form
            :model="formData"
            label-position="left"
            ref="formData"
            :rules="rules"
        >
          <el-form-item label="Tên khoản thu cũ">
            <el-input v-model="nameRevenus" :disabled=true></el-input>
          </el-form-item>
          <el-form-item label="Tên khoản thu mới" prop="newNameKt">
            <el-input v-model="formData.newNameKt" placeholder="Nhập tên khoản thu mới"></el-input>
            <span class="error-message">{{ customErrorMessages.newNameKt }}</span>
          </el-form-item>
        </el-form>
        <div class="custom_button">
          <el-button :disabled="checkDelete" size="medium" type="warning" @click="deleteExpenses"
                     style="margin-right: 230px" v-if="showSaveChange">
            <i class="el-icon-delete"/> Xóa khoản
          </el-button>
          <el-button size="medium" :disabled="checkDelete" type="warning" @click="deleteExpenses"
                     style="margin-right: 325px" v-else>
            <i class="el-icon-delete"/> Xóa khoản
          </el-button>
          <el-button
              type="success"
              size="medium"
              :loading="loadingChangeName"
              @click="submitForm"
              v-if="showSaveChange"
          >
            <i class="el-icon-circle-check"/> Lưu
          </el-button>
          <el-button size="medium" type="danger" @click="handleCloseChangeNameRevenusDialog">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {mapState} from "vuex";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";

export default {
  data() {
    return {
      showSaveChange: false,
      customErrorMessages: {
        newNameKt: ""
      },
      loadingChangeName: false,
      formData: {
        month: "",
        collectionTurn: "",
        year: "",
        newNameKt: "",
        oldNameKt: "",
        idGrade: "",
        idClass: ""
      },
      rules: {
        newNameKt: [
          {
            validator: this.validateNameChange,
            trigger: "change"
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('finance2Module', ['dataSearch','listKT'])
  },
  props: {
    isOpenChangeNameRevenusDialog: {
      type: Boolean,
      default: () => false
    },
    nameRevenus: {
      type: String
    },
    checkDelete: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    validateNameChange(rule, value, callback) {
      for (let i = 1; i <= 20; i++) {
        if (!value) {// Kiểm tra nếu giá trị rỗng
          this.customErrorMessages.newNameKt = "Giá trị không được để trống!";
          this.showSaveChange = false
          break
        } else if (!isNaN(value)) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKt = "Không được nhập tên khoản thu là các số!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === ("Khoản thu " + i.toString()).toLowerCase().replace(/\s/g, "")) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKt = "Mời bạn nhập khoản thu khác với khoản thu mặc định!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === this.listKT[`nameKt${i}`].toLowerCase().replace(/\s/g, "")) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKt = "Tên khoản thu trùng lặp với khoản thu đã có sẵn!";
          break
        } else {
          this.showSaveChange = true
          this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
          callback();
        }
      }
    },
    handleCloseChangeNameRevenusDialog() {
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
      this.$emit("closeChangeNameRevenusDialog", false);
      this.loadingChangeName = false;
      this.customErrorMessages.newNameKt = ""
      this.$refs.formData.resetFields()
    },

    async submitForm() {
      try {
        this.loadingChangeName = true;
        this.formData.month = this.dataSearch.month
        this.formData.collectionTurn = this.dataSearch.collectionTurn
        this.formData.year = this.dataSearch.createdYear
        this.formData.idClass = this.dataSearch.idClass
        this.formData.idGrade = this.dataSearch.idGrade
        this.formData.oldNameKt = this.nameRevenus
        await financeKidsV2Service.changeNameKT(this.formData).then(() => {
          this.$notify.success({
            title: "Thông báo",
            message: "Đổi tên thành công",
          });
          this.handleCloseChangeNameRevenusDialog()
        })
      } catch (error) {
        this.$message({
          message: "Đổi tên thất bại!",
          type: "error",
        });
      } finally {
        this.loadingChangeName = false;
      }
    },
    // Xóa khoản thu
    deleteExpenses() {
      this.$confirm('Thao tác này sẽ xóa khoản thu hiện tại. Tiếp tục?', 'Warning', {
        title: 'Cảnh báo',
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        const request = {
          idGrade: this.dataSearch.idGrade,
          idClass: this.dataSearch.idClass,
          year: this.dataSearch.createdYear,
          month: this.dataSearch.month,
          collectionTurn: this.dataSearch.collectionTurn
        }
        financeKidsV2Service.deleteLastExpense(request).then(() => {
          this.$message({
            type: 'success',
            message: 'Xóa khoản thu thành công'
          })
          this.handleCloseChangeNameRevenusDialog()
          ;
        })
      }).catch(() => {
        this.$message({
          type: "warning",
          message: 'Thao tác hủy bỏ thành công'
        });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.custom_button {
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.error-message {
  color: red;
}
</style>