<template>
  <div class="content">
    <KidsSearchTable>
      <KidsExcelTable/>
    </KidsSearchTable>
  </div>
</template>
<script>
import KidsSearchTable from "@/components/FinanceVer2/ByClass/KidsTableSearch.vue";
import KidsExcelTable from "@/components/FinanceVer2/ByClass/KidsExcelTable.vue";

export default {
  components: {KidsExcelTable, KidsSearchTable}
}
</script>