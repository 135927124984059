import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";

export default {
    data() {
        return {
            loadingUpdate: false,
            updateAttendance: true,
            updateAttendanceForGroup: true,
        }
    },
    methods: {

        // cập nhật điểm danh
        updateAttendanceData(idGrade, idClass, month, year, dataSearch) {
            this.loadingUpdate = true
            this.updateAttendance = false;
            this.$store.dispatch("finance2Module/init_kids_data", dataSearch);
            financeKidsV2Service.updateAttendanceData(idGrade, idClass, month, year).then((response) => {

                this.loadingUpdate = false
                this.updateAttendance = true
                if (response.data.message === "Cập nhật thành công") {
                    this.$notify.success({
                        title: "Thông báo",
                        message: "Cập nhật thành công",
                    });
                }
            }).catch((error) => {
                    this.loadingUpdate = false
                    console.log(error)
                }
            )
        },

        //Hướng dẫn sử dụng tải file mẫu
        instructUploadFileSample() {
            financeKidsV2Service.instructUploadFileSample().then((res) => {
                const excelData = res.data;
                let excelFilename = `Hướng dẫn sử dụng.xlsx`;
                handleAndDownloadExcel(excelData, excelFilename);
            });
        },

    }
}