<template>
  <div class="table-content row-data">
    <!-- chi tiết khoản các học sinh -->
    <el-table
        element-loading-spinner="el-icon-loading"
        :empty-text="textTable"
        :data="responseFilterList"
        v-loading="loadingData"
        :element-loading-text="$tableLoading"
        element-loading-background="rgba(255,255,255, 0)"
        :header-cell-style="tableHeaderColor"
        @selection-change="handleSelectionChange"
        :max-height="$tableMaxHeight"
        border
    >
        <el-table-column
            type="selection"
            align="center"
            width="55"
        ></el-table-column>
        <el-table-column
            type="index"
            fixed="left"
            label="STT"
            width="50"
            align="center"
        ></el-table-column>

      <el-table-column width="150" fixed="left" label="Họ tên">
        <template v-slot:default="scope">
          <el-tooltip placement="top">
            <div slot="content">Mã học sinh: {{scope.row.kidCode}}<br>Bố/Mẹ: {{scope.row.fatherPhone}} - {{scope.row.motherPhone}}</div>
            <span>{{ scope.row.fullName }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="90" label="Biệt danh" prop="nickName" align="center">
      </el-table-column>
      <el-table-column align="center" width="70" label="Hiển thị">
        <template v-slot:default="scope">
          <el-checkbox
              v-model="scope.row.orderHidden"
              @change="handelShowOrHidden(scope.row)"
              :disabled="
              scope.row.status === null || scope.row.status === 'Ẩn hóa đơn'
            "
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Thông tin bổ sung" align="center" width="90">
        <template v-slot:default="scope">
          <el-button
              size="mini"
              @click="handleOpenKidDetail(scope.row, scope.row.status)"
          ><i class="el-icon-document"></i
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" width="75" label="Tồn hóa đơn">
        <template v-slot:default="scope">
          <el-button
              class="click-in-row"
              type="text"
              @click="inventoryOrder(scope.row)"
              v-if="scope.row.count !== 0"
          >{{ scope.row.count }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" width="110" label="Trạng thái">
        <template v-slot:default="{ row }">
          <div :class="getStatusColor(row.status)">
              {{ getStatusValue(row.status) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="110" label="Chuyển thanh toán">
        <template v-slot:default="scope">
          <span v-if="scope.row.transactionEntry==='MONEY_WALLET'&& scope.row.afterMoney !==0" style="color: orange ">Không chuyển</span>
          <span v-if="scope.row.transactionEntry==='MONEY_MONTH'&& scope.row.afterMoney !==0" style="color: blue ">Sang tháng sau</span>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          width="105"
          label="Thiếu/Thừa tháng trước"
          prop="beforeMoney"
      >
        <template slot-scope="{ row }">
          {{ row.beforeMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="100" label="Phải thu tháng này">
        <template slot-scope="{ row }">
          {{ row.sumMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="105" label="Đã thu">
        <template v-slot:default="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="collectedMoneyMethod(scope.row, scope.row.status)">
            <span v-if="scope.row.collectedMoney">{{ scope.row.collectedMoney | formatCurrency }}</span>
            <span v-else><b>-</b></span>
          </el-button>
        </template>
      </el-table-column>

      <el-table-column align="right" width="100" label="Tiền mặt">
        <template slot-scope="{ row }">
          {{ row.cashMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="100" label="Chuyển khoản">
        <template slot-scope="{ row }">
          {{ row.transferMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="105" label="Thiếu / Thừa còn lại">
        <template v-slot:default="{ row }">
          {{ row.afterMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt1 !== 'Khoản thu 1'"
          align="right"
          width="115"
          :label="listKT.nameKt1"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt1)" style="cursor: pointer;">{{ listKT.nameKt1 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt1"> {{ scope.row.moneyKt1 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt2 !== 'Khoản thu 2'"
          align="right"
          width="115"
          :label="listKT.nameKt2"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt2)" style="cursor: pointer;">{{ listKT.nameKt2 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt2"> {{ scope.row.moneyKt2 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt3 !== 'Khoản thu 3'"
          align="right"
          width="115"
          :label="listKT.nameKt3"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt3)" style="cursor: pointer;">{{ listKT.nameKt3 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt3"> {{ scope.row.moneyKt3 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          v-if="listKT.nameKt4 !== 'Khoản thu 4'"
          width="115"
          :label="listKT.nameKt4"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt4)" style="cursor: pointer;">{{ listKT.nameKt4 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt4" style="text-align: right"> {{ scope.row.moneyKt4 | formatCurrency }}</span>
            <span v-else style="text-align: right">-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt5 !== 'Khoản thu 5'"
          align="right"
          width="115"
          :label="listKT.nameKt5"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt5)" style="cursor: pointer;">{{ listKT.nameKt5 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt5"> {{ scope.row.moneyKt5 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt6 !== 'Khoản thu 6'"
          align="right"
          width="115"
          :label="listKT.nameKt6"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt6)" style="cursor: pointer;">{{ listKT.nameKt6 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt6"> {{ scope.row.moneyKt6 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt7 !== 'Khoản thu 7'"
          align="right"
          width="115"
          :label="listKT.nameKt7"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt7)" style="cursor: pointer;">{{ listKT.nameKt7 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt7"> {{ scope.row.moneyKt7 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt8 !== 'Khoản thu 8'"
          align="right"
          width="115"
          :label="listKT.nameKt8"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt8)" style="cursor: pointer;">{{ listKT.nameKt8 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt8"> {{ scope.row.moneyKt8 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt9 !== 'Khoản thu 9'"
          align="right"
          width="115"
          :label="listKT.nameKt9"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt9)" style="cursor: pointer;">{{ listKT.nameKt9 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt9"> {{ scope.row.moneyKt9 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt10 !== 'Khoản thu 10'"
          align="right"
          width="115"
          :label="listKT.nameKt10"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt10)" style="cursor: pointer;">{{ listKT.nameKt10 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt10"> {{ scope.row.moneyKt10 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt11 !== 'Khoản thu 11'"
          align="right"
          width="115"
          :label="listKT.nameKt11"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt11)" style="cursor: pointer;">{{ listKT.nameKt11 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt11"> {{ scope.row.moneyKt11 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt12 !== 'Khoản thu 12'"
          align="right"
          width="115"
          :label="listKT.nameKt12"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt12)" style="cursor: pointer;">{{ listKT.nameKt12 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt12"> {{ scope.row.moneyKt12 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt13 !== 'Khoản thu 13'"
          align="right"
          width="115"
          :label="listKT.nameKt13"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt13)" style="cursor: pointer;">{{ listKT.nameKt13 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt13"> {{ scope.row.moneyKt13 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt14 !== 'Khoản thu 14'"
          align="right"
          width="115"
          :label="listKT.nameKt14"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt14)" style="cursor: pointer;">{{ listKT.nameKt14 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt14"> {{ scope.row.moneyKt14 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt15 !== 'Khoản thu 15'"
          align="right"
          width="115"
          :label="listKT.nameKt15"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt15)" style="cursor: pointer;">{{ listKT.nameKt15 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt15"> {{ scope.row.moneyKt15 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt16 !== 'Khoản thu 16'"
          align="right"
          width="115"
          :label="listKT.nameKt16"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt16)" style="cursor: pointer;">{{ listKT.nameKt16 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt16"> {{ scope.row.moneyKt16 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt17 !== 'Khoản thu 17'"
          align="right"
          width="115"
          :label="listKT.nameKt17"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt17)" style="cursor: pointer;">{{ listKT.nameKt17 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt17"> {{ scope.row.moneyKt17 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt18 !== 'Khoản thu 18'"
          align="right"
          width="115"
          :label="listKT.nameKt18"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt18)" style="cursor: pointer;">{{ listKT.nameKt18 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt18"> {{ scope.row.moneyKt18 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt19 !== 'Khoản thu 19'"
          align="right"
          width="115"
          :label="listKT.nameKt19"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt19)" style="cursor: pointer;">{{ listKT.nameKt19 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt19"> {{ scope.row.moneyKt19 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="listKT.nameKt20 !== 'Khoản thu 20'"
          align="right"
          width="115"
          :label="listKT.nameKt20"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameRevenus(listKT.nameKt20)" style="cursor: pointer;">{{ listKT.nameKt20 }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.status) !=='Chưa có hóa đơn'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.status)
            "
          >
            <span v-if="scope.row.moneyKt20"> {{ scope.row.moneyK20 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Tác vụ" fixed="right" align="center" v-if="responseFilterList.length <=10" width="215px">
        <template slot-scope="scope">
          <div style="display: inline-flex">
            <el-button style="display: none"></el-button>
            <!--           giữ nút này do lỗi framework k cập nhật, check được :disable(thanh toán) khi render giao diện-->
            <el-button
                v-if="checkPermission(['fees_2_excel_cash_payment'])"
                size="mini"
                :type="getButtonCashierType(scope.row)"
                @click="orderKidsCashierMethod(scope.row)"
                :disabled="scope.row.status === null || scope.row.status === 'Ẩn hóa đơn'|| scope.row.sumMoney === 0"
            >Thanh toán</el-button>

            <el-button
                type="success"
                size="mini"
                @click="orderKidsHistoryMethod(scope.row)"
                :disabled="
              scope.row.status === null|| scope.row.sumMoney === 0
            "
            >
              Lịch sử
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Tác vụ" fixed="right" align="center" v-else width="198px">
        <template slot-scope="scope">
          <div style=" display: inline-flex">
            <el-button style="display: none"></el-button>
            <!--           giữ nút này do lỗi framework k cập nhật, check được :disable(thanh toán) khi render giao diện-->
            <el-button
                v-if="checkPermission(['fees_2_excel_cash_payment'])"
                size="mini"
                :type="getButtonCashierType(scope.row)"
                @click="orderKidsCashierMethod(scope.row)"
                :disabled="scope.row.status === null || scope.row.status === 'Ẩn hóa đơn'|| scope.row.sumMoney === 0"
            >Thanh toán</el-button>

            <el-button
                type="success"
                size="mini"
                @click="orderKidsHistoryMethod(scope.row)"
                :disabled="
              scope.row.status === null|| scope.row.sumMoney === 0
            "
            >
              Lịch sử
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <HistoryOrderKidsDialog
        :isOpenHistoryDialog="showHistoryDialog"
        @closeHistoryOrderKids="closeOrderKidsHistoryDialog()"
        ref="OrderKidsHistoryDialog"
    />
    <ExcelKidDetail
        :isOpenKidDetail="isOpenKidDetailDialog"
        :kids-detail-data="kidDetailData"
        @closeDetailKid="handleCloseDetailKid"
        ref="ExcelKidDetail"
    />

    <OrderKidsExcelCashier
        :isOpenCashierDialog="showOrderKidsExcelCashierDialog"
        @dialog-close="closeOrderKidsExcelCashierDialog"
        :isActivatedAccount="isActivatedAccount"
        ref="OrderKidsExcelCashier"
    />

    <CollectedMoneyDialog
        v-if="checkPermission(['fees_2_excel_cash_payment'])"
        :isOpenCollectedMoney="showCollectedMoney"
        @closeCollectedMoney="closeCollectedMoneyDialog()"
        ref="CollectedMoneyDialog"
    />

    <InventoryOrderDialog
        :isOpenInventoryOrder="showInventoryOrder"
        @closeInventoryOrder="closeInventoryOrderDialog()"
        ref="InventoryOrderDialog"
    />

    <DetailKTDialog
        :isOpenDetailKT="detailKT"
        @closeDetailKT="closeDetailKTDialog()"
        ref="DetailKTDialog"
    />

    <ChangeNameRevenusDialog
        v-if="checkPermission(['fees_2_excel_cash_payment'])"
        :isOpenChangeNameRevenusDialog = "modifierLesRevenus"
        :nameRevenus = "nameRevenus"
        :checkDelete="checkDelete"
        @closeChangeNameRevenusDialog= "closeChangeNameRevenusDialog"
    />

  </div>
</template>
<script>
import ExcelKidDetail from "@/components/FinanceVer2/ByClass/ExcelTableDialog/ExcelKidDetailDialog.vue";
import { mapState} from "vuex";
import HistoryOrderKidsDialog from "@/components/FinanceVer2/ByClass/ExcelTableDialog/HistoryOrderKidsDialog.vue";
import OrderKidsExcelCashier from "@/components/FinanceVer2/ByClass/ExcelTableDialog/OrderKidsExcelCashier.vue";
import CollectedMoneyDialog from "@/components/FinanceVer2/ByClass/ExcelTableDialog/CollectedMoneyDialog.vue";
import InventoryOrderDialog from "@/components/FinanceVer2/ByClass/ExcelTableDialog/InventoryOrderDialog.vue";
import DetailKTDialog from "@/components/FinanceVer2/ByClass/ExcelTableDialog/DetailKTDialog.vue";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import tableValidateMixin from "@/global/mixin/tableValidate.mixin";
import countColumnActionMixin from "@/global/mixin/countColumnAction.mixin";
import ChangeNameRevenusDialog from "@/components/FinanceVer2/ByClass/TableSearchDialog/ChangeNameRevenusDialog.vue";
import checkPermission from "@/utils/permission";

export default {
  name: "KidsExcelTable",
  components: {
    ChangeNameRevenusDialog,
    DetailKTDialog,
    InventoryOrderDialog,
    CollectedMoneyDialog,
    HistoryOrderKidsDialog,
    ExcelKidDetail,
    OrderKidsExcelCashier,
  },
  computed: {
    ...mapState("finance2Module", [
      "responseFilterList",
      "loadingData",
      "listKT",
      "dataSearch","checkResponse"
    ]),
  },
  mixins: [tableValidateMixin,countColumnActionMixin],
  data() {
    return {
      // Hiển thị/ Bỏ hiển thị Hóa đơn
      showOrHidden: {
        date: "",
        idKid: "",
        collectionTurn: "",
        status: false,
      },
      // dialog field
      kidDetailData: {},
      isOpenKidDetailDialog: false,
      showHistoryDialog: false,
      showOrderKidsExcelCashierDialog: false,
      multipleSelection: [],
      textTable: this.$tableEmpty,
      showCollectedMoney: false,
      showInventoryOrder: false,
      detailKT: false,
      modifierLesRevenus: false,
      nameRevenus: "",
      checkDelete: true,
      isActivatedAccount: false,
    };
  },
  watch:{
    checkResponse(){
      if (this.checkResponse === false){
        this.textTable = this.$tableEmpty
      }
    },
  },

  methods: {
    checkPermission,
    getButtonCashierType(row){
      if (row.status === "Đóng đủ 100%" || row.status === "Đóng đủ thừa" || row.status === "Đóng đủ thiếu"){
        return 'info';
      }
      return 'primary';
    },
    changeNameRevenus(name){
      this.checkDelete = true
      this.modifierLesRevenus = true;
      this.nameRevenus =name;
      const listNameKt = []
      for (let i = 1; i <=20 ; i++) {
        if (this.listKT[`nameKt${i}`] !== `Khoản thu ${i}`){
          listNameKt.push(this.listKT[`nameKt${i}`])
        }
      }
      if (name === listNameKt[listNameKt.length -1]){
        this.checkDelete = false
      }
    },
    handelShowOrHidden(row) {
      const date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      this.showOrHidden.date = date;
      this.showOrHidden.idKid = row.id;
      this.showOrHidden.collectionTurn = this.dataSearch.collectionTurn;
      if(row.orderHidden){
        this.showOrHidden.status =true;
        this.$store.commit("finance2Module/set_show_app_message", row.fullName);
      }
      else{
        this.showOrHidden.status =false
        this.$store.commit("finance2Module/set_hidden_app_message", row.fullName);

      }
      financeKidsV2Service
          .showOrHiddenOrderKids(this.showOrHidden)
          .then((res) => {
            return this.$message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });

          })
          .catch(() => {
            return this.$message({
              showClose: true,
              message: `Lỗi hệ thống `,
              type: "danger",
            });
          });
    },
    // dialog hiển thị thông tin điểm danh
    handleOpenKidDetail(row, statusOrder) {
      this.isOpenKidDetailDialog = true;
      this.$refs.ExcelKidDetail.getInfoByMonthAndTurn(
          this.dataSearch.month,
          row.id,
          this.dataSearch.collectionTurn,
          statusOrder,
          this.dataSearch.createdYear,
      );
    },
    handleCloseDetailKid(close) {
      this.isOpenKidDetailDialog = close;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    closeChangeNameRevenusDialog(close){
      this.modifierLesRevenus = close;
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
    },
    /**
     * Mở dialog lưu lịch sử thanh toán
     * @param row
     */
    orderKidsCashierMethod(row) {
      if (this.dataSearch.collectionTurn === 0) {
        this.$message({
          message: "Bạn chưa chọn đợt thu",
          type: "error",
        });
      } else {
        let idKid = row.id;
        let kidCode = row.kidCode;
        let kidName = row.fullName;
        let orderKidCode = row.orderKidCode;
        let month = this.dataSearch.month;
        let year = this.dataSearch.createdYear;

        let collectionTurn = this.dataSearch.collectionTurn;
        this.showOrderKidsExcelCashierDialog = true;
        this.$refs.OrderKidsExcelCashier.getDataCashierInitial(
            idKid,
            kidCode,
            kidName,
            month,
            collectionTurn,
            orderKidCode,
            year
        );
        this.isActivatedAccount = row.fatherPhone !== "" || row.motherPhone !== "";
      }
    },

    /**
     * Mở dialog hiển thị lịch sử thanh toán
     * @param row
     */
    orderKidsHistoryMethod(row) {
      this.showHistoryDialog = true;
      this.$refs.OrderKidsHistoryDialog.getOrderHistoryInitial(
          row.id,
          this.dataSearch.month,
          this.dataSearch.collectionTurn,
          this.dataSearch.createdYear
      );
    },

    closeOrderKidsHistoryDialog() {
      this.showHistoryDialog = false;
      // this.searchOrderKidsByProperties();
      this.$store.commit('finance2Module/get_status_excel')
    },

    /**
     * Mở dialog hiển thị tiền đã thu
     */
    collectedMoneyMethod(row, status) {
      this.showCollectedMoney = !this.showCollectedMoney;
      this.$refs.CollectedMoneyDialog.getCollectedMoney(
          this.dataSearch.month,
          row.id,
          this.dataSearch.collectionTurn,
          status,
          this.dataSearch.createdYear
      );
    },

    closeCollectedMoneyDialog(boolean) {
      this.showCollectedMoney = boolean;
    },

    /**
     * Mở dialog hiển thị tồn hóa đơn
     */
    inventoryOrder(row) {
      this.showInventoryOrder = !this.showInventoryOrder;
      this.$refs.InventoryOrderDialog.getInventoryOrder(
          row.kidCode,
          row.fullName,
          row.id
      );
    },

    closeInventoryOrderDialog(boolean) {
      this.showInventoryOrder = boolean;
      this.$store.commit('finance2Module/get_status_excel')

    },

    /**
     * Mở dialog hiển thị chi tiết khoản thu
     */
    getDetailKT(row, nameKt, statusOrder) {
      this.detailKT = !this.detailKT;
      this.$refs.DetailKTDialog.showDetailKT(
          this.dataSearch.month,
          row.kidCode,
          this.dataSearch.collectionTurn,
          nameKt,
          statusOrder,
          this.dataSearch.createdYear
      );
    },

    closeDetailKTDialog(boolean) {
      this.detailKT = boolean;
    },

    closeOrderKidsExcelCashierDialog() {
      this.showOrderKidsExcelCashierDialog = false;
      this.$store.commit('finance2Module/get_status_excel')
    },
    tableHeaderColor(column) {
      this.countNonEmptyAttributes(this.listKT)
      if (14<column.columnIndex&& column.columnIndex <= (14+this.countColumnAttributes) ){
        return "background-color: #ea580cb3;color: #fff;font-weight: light;"
      }
      return "background-color: #78a5e7;color: #fff;font-weight: light;";
    },
    // chọn nhiều truonwgf
    handleSelectionChange(val) {
      this.multipleSelection = val; // val laf 1 array object
      this.listNullBillKids = [];
      this.listHiddenOrder = []
      this.listShowForApp=[]
      this.listHiddenForApp=[]
      this.multipleSelection.forEach((row) => {
        if (this.validateNullBillStudent(row)) {
          this.listNullBillKids = [...this.listNullBillKids, row.fullName];
        }
        if (this.validateHiddenOrder(row)) {
          this.listHiddenOrder = [...this.listHiddenOrder, row.fullName]; // lấy danh sách bị ẩn
        }
        if(row.orderHidden){
          this.listShowForApp=[...this.listShowForApp,row.fullName]// lấy danh sách hiển thị lên app
        }
        if(!row.orderHidden){
          this.listHiddenForApp=[...this.listHiddenForApp,row.fullName]// lấy danh sách không hiện thị lên app
        }
      });
      // quản lý danh sách null
      this.$store.commit(
          "finance2Module/set_hidden_order_kids",
          this.listHiddenOrder
      );
      this.$store.commit(
          "finance2Module/set_show_for_app",
          this.listShowForApp
      );
      this.$store.commit(
          "finance2Module/set_hidden_for_app",
          this.listHiddenForApp
      );
      this.$store.commit(
          "finance2Module/set_null_bill_kids",
          this.listNullBillKids
      );
      // eslint-disable-next-line no-unused-vars
      let multipleSelectionCopy = this.multipleSelection;
      let idKidList = [];
      multipleSelectionCopy = multipleSelectionCopy.map((item) => {
        idKidList = [...idKidList, item.id];
      });
      this.$store.commit("finance2Module/set_id_kid_list", idKidList);
    },

    getStatusValue(status) {
      return status !== null ? status : "Chưa có hóa đơn";
    },

    getStatusColor(status) {
      if (status === null) {
        return "status-red";
      } else if (status === "Chưa đóng 100%" || status === "Chưa đóng đủ") {
        return "status-orange";
      } else if (status === "Đóng đủ 100%" || status === "Đóng thừa") {
        return "status-blue";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial,serif;
}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}

/* Định nghĩa các lớp màu sắc */
.status-red {
  color: red;
}

.status-orange {
  color: orange;
}

.status-blue {
  color: blue;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 2px 0;
}
/deep/.el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFF;
}

span.el-tooltip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

</style>