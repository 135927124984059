import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";

export default {
    data() {
        return {
            loadingBill: false,
            isOrderOpen: false,
            isOrderDestroy: false,
            exportBillAll:{
                date: "",
                idGrade: "",
                idClass: "",
                guiId: "",
            }
        }
    },
    methods: {
        // In hóa đơn hàng loạt theo tháng
        exportBillAllByMonth(date, idGrade, idClass, guiId) {
            this.exportBillAll.date = date;
            this.exportBillAll.idGrade = idGrade;
            this.exportBillAll.idClass = idClass;
            this.exportBillAll.guiId = guiId;
            financeKidsV2Service.exportBillAllByMonth(this.exportBillAll).then((response) => {
                if (response.data.message === "processing") {
                    this.$notify.info({
                        title: "Thông báo",
                        message: "Dữ liệu đang được xử lý !",
                    });
                }
                const checkProcess = setInterval(() => {
                    financeKidsV2Service
                        .exportBillAllByMonth(this.exportBillAll)
                        .then((res) => {
                            // chạy neeus k conf processing
                            if (res.data.message === "processing") {
                                this.loadingBill = true
                                return;
                            }
                            if (res.data.message === "fail") {
                                this.loadingBill = false
                                clearInterval(checkProcess)
                                this.$notify.error({
                                    title: "Thông báo",
                                    message: "Lỗi không xuất được dữ liệu !",
                                });
                                return;
                            } else {
                                this.loadingBill = false
                                this.$notify.success({
                                    title: "Thông báo",
                                    message: "Dữ liệu xuất thành công",
                                });

                                const excelFilename = res.data.message.split('complete_')[1]; // Provide a desired filename for the downloaded file
                                clearInterval(checkProcess);
                                return financeKidsV2Service
                                    .dowloadBillToExcel(guiId)
                                    .then((res) => {
                                        const excelData = res.data;
                                    // Provide a desired filename for the downloaded file
                                        handleAndDownloadExcel(excelData, excelFilename);
                                    });

                            }
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }, 5000);
            })
        },

        // khôi phục hóa đơn
        openOrder(idKidList, dataSearch, showListForApp) {
            if (idKidList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có học sinh nào được chọn",
                    type: "error",
                });
            } else {
                this.$confirm("Bạn có muốn khôi phục hóa đơn?", "Thông báo !", {
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    confirmButtonText: "Có",
                    cancelButtonText: "Không",
                }).then(() => {
                    showListForApp.date = this.$funcCommon.formatMY(dataSearch.createdYear, dataSearch.month);
                    showListForApp.idKidList = idKidList.split(",").map(Number);
                    showListForApp.collectionTurn = dataSearch.collectionTurn;

                    financeKidsV2Service
                        .openOrderKidsExcel(showListForApp)
                        .then(() => {
                            this.$message({
                                message: "Khôi phục hóa đơn thành công",
                                type: "success",
                            });
                            this.isOrderOpen = !this.isOrderOpen
                        })
                        .catch((err) => {
                            this.$message({
                                message: err.response.data.message,
                                type: "error",
                            });
                        });
                });
            }
        },

        // xóa hóa đơn hoàn toàn
        destroyOrder(idKidList, dataSearch, showListForApp) {
            if (idKidList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có học sinh nào được chọn",
                    type: "error",
                });
            } else {
                this.$confirm("Bạn có chắc chắn muốn xóa hóa đơn?", "Thông báo !", {
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    confirmButtonText: "Có",
                    cancelButtonText: "Không",
                }).then(() => {
                    showListForApp.date = this.$funcCommon.formatMY(dataSearch.createdYear, dataSearch.month);
                    showListForApp.idKidList = idKidList.split(",").map(Number);
                    showListForApp.collectionTurn = dataSearch.collectionTurn;

                    financeKidsV2Service
                        .destroyOrderKidsExcel(showListForApp)
                        .then(() => {
                            this.$message({
                                message: "Xóa hóa đơn thành công",
                                type: "success",
                            });
                            financeKidsV2Service
                                .getCollectionTurn(
                                    dataSearch.month,
                                    dataSearch.idGrade,
                                    dataSearch.idClass,
                                    dataSearch.createdYear
                                )
                                .then((res) => {
                                    if (res.data === 0) {
                                        this.dataSearch.collectionTurn = null;
                                        this.collectionTurn = null;
                                        this.$store.commit("finance2Module/loading_data")
                                        this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
                                        return;
                                    }
                                    this.collectionTurn = res.data;
                                    this.dataSearch.collectionTurn = this.collectionTurn;
                                    this.$store.commit("finance2Module/loading_data")
                                    this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
                                });
                            this.isOrderDestroy = !this.isOrderDestroy
                        })
                        .catch((err) => {
                            this.$message({
                                message: err.response.data.message,
                                type: "error",
                            });
                        });
                });
            }
        },


    }
}