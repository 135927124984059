<template>
  <div>
    <el-dialog
        :title="'Thanh toán hàng loạt'"
        :visible.sync="isOpenAllCashierDialog"
        :empty-text="textTable"
        width="800px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
    >
      <div style="text-align: left">
        <span style="color: red">{{ noteMessage }}</span>

      </div>

      <br/>
      <div>
        <el-row :gutter="20">
          <el-col>
            <el-form
                label-width="155px"
                :model="dataInput"
                label-position="left"
                ref="dataInput"
            >
              <el-form-item label="Loại thanh toán" prop="transferMoneyType">
                <el-radio-group
                    @change="onChangeRadioPaymentType"
                    v-model="dataInput.paymentType"
                    style="margin-top: 12px"
                >
                  <el-radio style="color: black" label="cash_money"
                  >Tiền mặt
                  </el-radio>
                  <el-radio style="color: black" label="transfer_money"
                  >Chuyển khoản
                  </el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item
                  label="Người thanh toán"
                  prop="parentPay"
                  style="display: inline-block; width: 45%"
              >
                <el-input
                    v-model="dataInput.parentPay"
                    placeholder="Phụ huynh học sinh"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="Ngày thanh toán"
                  prop="datePayment"
                  style="display: inline-block; width: 45%; float: right"
              >
                <el-date-picker
                    v-model="dataInput.datePayment"
                    type="date"
                    :clearable="false"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    format="dd-MM-yyyy"
                    placeholder="Chọn ngày"
                    :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>


              <el-form-item label="Mô tả" prop="noteHistory">
                <el-input
                    v-model="noteHistory"
                    placeholder="Nhập mô tả"
                ></el-input>
              </el-form-item>

              <el-form-item label="Gửi qua App">
                <el-checkbox
                    v-model="statusAttachNotifyFinance"
                    style="font-weight: bold; margin-top: 12px"
                >Đính kèm thông tin thanh toán
                </el-checkbox>
                <el-checkbox
                    v-model="attachOrderPDF"
                    style="font-weight: bold; margin-left: 25px"
                >Đính kèm hoá đơn
                </el-checkbox>
              </el-form-item>

              <br/>
              <br/>
            </el-form>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            size="medium"
            :loading="loadingButton"
            mini
            @click="submitForm()"
        >
          <i class="el-icon-circle-check"/>
          <span>Thanh toán</span>
        </el-button>


        <el-button type="danger" size="medium" @click="closeDialog()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "AllCashier",
  // eslint-disable-next-line vue/no-unused-components
  props: {
    isOpenAllCashierDialog: {
      type: Boolean,
      default: () => false,
    },
    bulkPayments: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {
    ...mapState("finance2Module", ["dataSearch"]),
  },
  data() {
    return {
      noteMessage: "",
      attachOrderPDF: false,
      statusAttachNotifyFinance: false,
      idKidList: [],
      textTable: this.$tableEmpty,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      /**
       * Data gửi về để lưu lịch sử thanh toán
       */

      dataInput: {
        idKids: Number,
        collectionTurn: 0,
        collectedMoney: 0,
        cash_money: 0,
        transfer_money: 0,
        afterMoney: 0,
        paymentType: "cash_money",
        parentPay: "PHHS",
        date: "",
        datePayment: moment(String(new Date())).format("YYYY-MM-DD"),
        noteHistory: null,
        transferMoneyType: "",
      },
      noteHistory: "",
      loadingButton: false,
    };
  },

  watch: {
    noteHistory(newValue) {
      this.dataInput.noteHistory = newValue;
    },
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.paid == 0) {
        return "color: #F56C6C";
      } else if (row.paid < row.money) {
        return "color: #E6A23C";
      }
    },

    clearData() {
      this.idKidList = [];
      this.noteMessage = "";
      this.loadingButton = false;
      this.attachOrderPDF = false;
      this.statusAttachNotifyFinance = false;
      this.noteHistory = "";
      this.$refs["dataInput"].resetFields();
    },

    /**
     * Đóng Dialog
     */

    closeDialog() {
      this.loadingButton = false
      this.$emit("dialog-close");
      setTimeout(() => {
        this.clearData();
      }, 300);
    },


    /**
     * Nhận dữ liệu từ cha, khởi tạo dữ liệu hiển thị ra màn hình
     * @param month
     * @param collectionTurn
     * @param idKidList
     * @param year
     */

    getDataAllCashierInitial(month, collectionTurn, idKidList, year) {
      this.noteMessage = "* Các học sinh được chọn sẽ được thanh toán đủ 100% cho đợt " + collectionTurn + " - tháng " + month
      this.idKidList = idKidList.split(",").map(Number);
      this.dataInput.collectionTurn = collectionTurn;
      month < 10 ? this.dataInput.date = year + "-0" + month + "-15" : this.dataInput.date = year + "-" + month + "-15";
    },


    /**
     * Thay đổi chọn radio loại thanh toán
     */
    onChangeRadioPaymentType() {
      this.transactionEntry = this.dataInput.transferMoneyType;
    },


    /**
     * Gửi form lên để lưu dữ liệu vào DB
     */

    submitForm() {
      this.$emit('update:bulkPayments', true);
      let dataSubmit = {
        idKidList: [],
        date: "",
        collectionTurn: Number,
        noteHistory: String,
        datePayment: null,
        parentPay: String,
        categoryMoney: String,
        attachOrderPDF: Boolean,
        statusAttachNotifyFinance: Boolean,

      };
      this.loadingButton = true;
      this.$confirm(
          "Bạn có chắc chắn muốn thanh toán không ?",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Có",
            closeOnClickModal: false,
            cancelButtonText: "Không",
          }
      )
          .then(() => {
            dataSubmit.idKidList = this.idKidList;
            dataSubmit.date = this.dataInput.date;
            dataSubmit.collectionTurn = this.dataInput.collectionTurn;
            dataSubmit.noteHistory = this.dataInput.noteHistory;
            dataSubmit.datePayment = this.dataInput.datePayment;
            dataSubmit.parentPay = this.dataInput.parentPay;
            dataSubmit.categoryMoney = this.dataInput.paymentType;
            dataSubmit.attachOrderPDF = this.attachOrderPDF;
            dataSubmit.statusAttachNotifyFinance = this.statusAttachNotifyFinance;
            financeKidsV2Service
                .paymentAll(dataSubmit)
                .then(() => {
                  this.$message({
                    message: "Thanh toán thành công.",
                    type: "success",
                  });
                  this.$emit('update:bulkPayments', false);
                  this.loadingButton = false
                  this.closeDialog()
                })
                .catch((resp) => {
                  this.$message({
                    message: resp.data.message,
                    type: "error",
                  });
                  this.closeDialog()
                  this.loadingButton = false;
                });
          })
          .catch(() => {
            this.closeDialog()
            this.$emit('update:bulkPayments', false);
            this.$message({
              message: "Lỗi không gửi được thông báo",
              type: "error",
            });
            this.loadingButton = false;
          })
    }

  },

};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  word-break: break-word;
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-dialog__body {
  padding-bottom: 5px;
}

/deep/ .el-form-item__label {
  color: black;
}

.el-row {
  margin-top: 15px;
  padding-top: 20px;
  background: #97bee7;
  color: black;
  border-radius: 5px;
}

.input-common {
  margin-right: 5px;
}

.text-money {
  margin-bottom: 15px;
}

/deep/ .el-form-item__content {
  line-height: 0;
}

/deep/ .negative-value .el-input__inner {
  color: red;
}
</style>