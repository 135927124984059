import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";

export default {
    methods:{
        // Hiển thị trên app phụ huynh cho  1 or nhều học sinh
        showListOrderForApp(idKidList,dataSearch,showListForApp) {
            if (idKidList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có học sinh nào được chọn",
                    type: "error",
                });
            } else {
                showListForApp.date = this.$funcCommon.formatMY(dataSearch.createdYear, dataSearch.month);
                showListForApp.idKidList = idKidList.split(",").map(Number);
                showListForApp.collectionTurn = dataSearch.collectionTurn;

                financeKidsV2Service
                    .showListOrderApp(showListForApp)
                    .then(() => {
                        this.$message({
                            message: "Hiển thị thành công",
                            type: "success",
                        });
                        this.$store.dispatch(
                            "finance2Module/init_kids_data",
                            dataSearch
                        );
                    })
                    .catch((err) => {
                        this.$message({
                            message: err.response.data.message,
                            type: "error",
                        });
                    });
            }
        },

        hiddenListOrderForApp(idKidList,dataSearch,showListForApp) {
            if (idKidList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có học sinh nào được chọn",
                    type: "error",
                });
            } else {
                showListForApp.date = this.$funcCommon.formatMY(dataSearch.createdYear, dataSearch.month);
                showListForApp.idKidList = idKidList.split(",").map(Number);

                showListForApp.collectionTurn = dataSearch.collectionTurn;

                financeKidsV2Service
                    .hiddenListOrderForApp(showListForApp)
                    .then(() => {
                        this.$message({
                            message: "Bỏ hiển thị thành công",
                            type: "success",
                        });
                        this.$store.dispatch(
                            "finance2Module/init_kids_data",
                            dataSearch
                        );
                    })
                    .catch((err) => {
                        this.$message({
                            message: err.response.data.message,
                            type: "error",
                        });
                    });
            }
        },
    }
}