<template>
  <div class="dialog-container">
    <el-dialog
        width="450px"
        title="Thêm khoản thu"
        :visible.sync="isOpenAddNameExpensesDialog"
        :before-close="handleCloseChangeNameRevenusDialog"
        :close-on-click-modal="false"
    >
      <div>
        <el-form
            :model="formData"
            label-position="left"
            ref="formData"
            :rules="rules"
        >
          <el-form-item label="Tên khoản thu mới" prop="newNameKT">
            <el-input v-model="formData.newNameKT" placeholder="Nhập tên khoản thu mới"></el-input>
            <span class="error-message">{{ customErrorMessages.newNameKT }}</span>
          </el-form-item>
        </el-form>
        <div class="custom_button">
          <el-button
              type="success"
              size="medium"
              :loading="loadingChangeName"
              @click="submitForm"
              v-if="showSaveChange"
          >
            <i class="el-icon-circle-check"/> Lưu
          </el-button>
          <el-button size="medium" type="danger" @click="handleCloseChangeNameRevenusDialog">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {mapState} from "vuex";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";

export default {
  name: "AddNameExpensesClass",
  data() {
    return {
      showSaveChange: false,
      customErrorMessages: {
        newNameKT: ""
      },
      loadingChangeName: false,
      formData: {
        month: "",
        collectionTurn: "",
        year: "",
        newNameKT: "",
        idGrade: "",
        idClass: ""
      },
      rules: {
        newNameKT: [
          {
            validator: this.validateNameChange,
            trigger: "change"
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('finance2Module', ['dataSearch','listKT'])
  },
  props: {
    isOpenAddNameExpensesDialog: {
      type: Boolean,
      default: () => false
    },
  },
  methods: {
    validateNameChange(rule, value, callback) {
      for (let i = 1; i <= 20; i++) {
        if (!value) {// Kiểm tra nếu giá trị rỗng
          this.customErrorMessages.newNameKT = "Giá trị không được để trống!";
          this.showSaveChange = false
          break
        }else if (!isNaN(value)){
          this.showSaveChange = false
          this.customErrorMessages.newNameKT = "Không được nhập tên khoản thu là các số!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === ("Khoản thu " + i.toString()).toLowerCase().replace(/\s/g, "")) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKT = "Mời bạn nhập khoản thu khác với khoản thu mặc định!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === this.listKT[`nameKt${i}`].toLowerCase().replace(/\s/g, "")){
          this.showSaveChange = false
          this.customErrorMessages.newNameKT = "Tên khoản thu trùng lặp với khoản thu đã có sẵn!";
          break
        } else {
          this.showSaveChange = true
          this.customErrorMessages.newNameKT = ""; // Xóa thông báo lỗi tùy chỉnh
          callback();
        }
      }
    },
    handleCloseChangeNameRevenusDialog() {
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch);
      this.$emit("close", false);
      this.loadingChangeName = false;
      this.customErrorMessages.newNameKT = ""
      this.$refs.formData.resetFields()
    },
    async submitForm() {
      try {
        this.loadingChangeName = true;
        this.formData.collectionTurn = this.dataSearch.collectionTurn
        this.formData.year = this.dataSearch.createdYear
        this.formData.month = this.dataSearch.month
        this.formData.idGrade = this.dataSearch.idGrade
        this.formData.idClass = this.dataSearch.idClass
        await financeKidsV2Service.addOneExpense(this.formData).then(() => {
          this.$notify.success({
            title: "Thông báo",
            message: "Thêm khoản thành công",
          });
          this.handleCloseChangeNameRevenusDialog()
        })
      } catch (error) {
        this.$message({
          message: "Thêm khoản thất bại!",
          type: "error",
        });
      } finally {
        this.loadingChangeName = false;
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.custom_button {
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.error-message {
  color: red;
}
</style>