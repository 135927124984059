var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isDialogTemplateVisible,
        top: "30vh",
        width: "530px",
        "close-on-click-modal": false,
        title: "Xóa mẫu học phí",
        "before-close": _vm.handleClose,
      },
      on: {
        open: _vm.handleFetchTemplate,
        "update:visible": function ($event) {
          _vm.isDialogTemplateVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticStyle: { height: "90px" } },
        [
          _c(
            "el-col",
            { staticStyle: { "margin-top": "5px" }, attrs: { span: 9 } },
            [_vm._v("Danh sách mẫu học phí :")]
          ),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: {
                    multiple: "",
                    size: "small",
                    placeholder: "Chọn file",
                  },
                  on: { change: _vm.handleSelectChange },
                  model: {
                    value: _vm.listTemplate,
                    callback: function ($$v) {
                      _vm.listTemplate = $$v
                    },
                    expression: "listTemplate",
                  },
                },
                _vm._l(_vm.listTemplateSchool, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.nameFile, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _vm.listTemplate.length !== 0
              ? _c("span", [
                  _vm._v(
                    "Mô tả file: " + _vm._s(_vm.selectedDescription) + " "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "custom_button" },
        [
          _vm.listTemplate.length !== 0
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    icon: "el-icon-delete",
                    size: "medium",
                  },
                  on: { click: _vm.handleDeleteTemplate },
                },
                [_vm._v("Xóa File")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: { click: _vm.handleClose },
            },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }