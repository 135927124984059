var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-content row-data" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
          ],
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "empty-text": _vm.textTable,
            data: _vm.responseFilterList,
            "element-loading-text": _vm.$tableLoading,
            "element-loading-background": "rgba(255,255,255, 0)",
            "header-cell-style": _vm.tableHeaderColor,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", align: "center", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              fixed: "left",
              label: "STT",
              width: "50",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "150", fixed: "left", label: "Họ tên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("Mã học sinh: " + _vm._s(scope.row.kidCode)),
                          _c("br"),
                          _vm._v(
                            "Bố/Mẹ: " +
                              _vm._s(scope.row.fatherPhone) +
                              " - " +
                              _vm._s(scope.row.motherPhone)
                          ),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(scope.row.fullName))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "90",
              label: "Biệt danh",
              prop: "nickName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "70", label: "Hiển thị" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        disabled:
                          scope.row.status === null ||
                          scope.row.status === "Ẩn hóa đơn",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handelShowOrHidden(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.orderHidden,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "orderHidden", $$v)
                        },
                        expression: "scope.row.orderHidden",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin bổ sung", align: "center", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleOpenKidDetail(
                              scope.row,
                              scope.row.status
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-document" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "75", label: "Tồn hóa đơn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.count !== 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "click-in-row",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.inventoryOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.count) + "\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "110", label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: _vm.getStatusColor(row.status) }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getStatusValue(row.status)) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "110",
              label: "Chuyển thanh toán",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.transactionEntry === "MONEY_WALLET" &&
                    scope.row.afterMoney !== 0
                      ? _c("span", { staticStyle: { color: "orange" } }, [
                          _vm._v("Không chuyển"),
                        ])
                      : _vm._e(),
                    scope.row.transactionEntry === "MONEY_MONTH" &&
                    scope.row.afterMoney !== 0
                      ? _c("span", { staticStyle: { color: "blue" } }, [
                          _vm._v("Sang tháng sau"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "105",
              label: "Thiếu/Thừa tháng trước",
              prop: "beforeMoney",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.beforeMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "100",
              label: "Phải thu tháng này",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.sumMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "105", label: "Đã thu" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.getStatusValue(scope.row.status) !== "Chưa có hóa đơn"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "click-in-row",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.collectedMoneyMethod(
                                  scope.row,
                                  scope.row.status
                                )
                              },
                            },
                          },
                          [
                            scope.row.collectedMoney
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.collectedMoney
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [_c("b", [_vm._v("-")])]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "100", label: "Tiền mặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.cashMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "100", label: "Chuyển khoản" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.transferMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "105",
              label: "Thiếu / Thừa còn lại",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.afterMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.listKT.nameKt1 !== "Khoản thu 1"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt1,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt1
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt1))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt1
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt1
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2294616837
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt2 !== "Khoản thu 2"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt2,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt2
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt2))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt2
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt2
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4253760069
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt3 !== "Khoản thu 3"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt3,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt3
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt3))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt3
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt3
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1369400709
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt4 !== "Khoản thu 4"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt4,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt4
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt4))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt4
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  scope.row.moneyKt4
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v("-")]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  750530533
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt5 !== "Khoản thu 5"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt5,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt5
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt5))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt5
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt5
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  123002373
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt6 !== "Khoản thu 6"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt6,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt6
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt6))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt6
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt6
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2082145605
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt7 !== "Khoản thu 7"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt7,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt7
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt7))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt7
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt7
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  69086341
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt8 !== "Khoản thu 8"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt8,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt8
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt8))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt8
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt8
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2545369285
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt9 !== "Khoản thu 9"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt9,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt9
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt9))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt9
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt9
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2951826693
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt10 !== "Khoản thu 10"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt10,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt10
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt10))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt10
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt10
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1373707109
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt11 !== "Khoản thu 11"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt11,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt11
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt11))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt11
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt11
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4000466725
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt12 !== "Khoản thu 12"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt12,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt12
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt12))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt12
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt12
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4035030629
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt13 !== "Khoản thu 13"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt13,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt13
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt13))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt13
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt13
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3568184357
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt14 !== "Khoản thu 14"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt14,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt14
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt14))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt14
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt14
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3877675621
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt15 !== "Khoản thu 15"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt15,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt15
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt15))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt15
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt15
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4149105189
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt16 !== "Khoản thu 16"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt16,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt16
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt16))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt16
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt16
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3992695653
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt17 !== "Khoản thu 17"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt17,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt17
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt17))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt17
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt17
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1451815205
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt18 !== "Khoản thu 18"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt18,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt18
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt18))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt18
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt18
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3003307365
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt19 !== "Khoản thu 19"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt19,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt19
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt19))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt19
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyKt19
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2750710053
                ),
              })
            : _vm._e(),
          _vm.listKT.nameKt20 !== "Khoản thu 20"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.listKT.nameKt20,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameRevenus(
                                        _vm.listKT.nameKt20
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.listKT.nameKt20))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.status) !==
                          "Chưa có hóa đơn"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.status
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyKt20
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK20
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2680344657
                ),
              })
            : _vm._e(),
          _vm.responseFilterList.length <= 10
            ? _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  align: "center",
                  width: "215px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { display: "inline-flex" } },
                            [
                              _c("el-button", {
                                staticStyle: { display: "none" },
                              }),
                              _vm.checkPermission(["fees_2_excel_cash_payment"])
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: _vm.getButtonCashierType(
                                          scope.row
                                        ),
                                        disabled:
                                          scope.row.status === null ||
                                          scope.row.status === "Ẩn hóa đơn" ||
                                          scope.row.sumMoney === 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderKidsCashierMethod(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Thanh toán")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    size: "mini",
                                    disabled:
                                      scope.row.status === null ||
                                      scope.row.sumMoney === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderKidsHistoryMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            Lịch sử\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4264879353
                ),
              })
            : _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  align: "center",
                  width: "198px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { display: "inline-flex" } },
                          [
                            _c("el-button", {
                              staticStyle: { display: "none" },
                            }),
                            _vm.checkPermission(["fees_2_excel_cash_payment"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: _vm.getButtonCashierType(scope.row),
                                      disabled:
                                        scope.row.status === null ||
                                        scope.row.status === "Ẩn hóa đơn" ||
                                        scope.row.sumMoney === 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderKidsCashierMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Thanh toán")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  disabled:
                                    scope.row.status === null ||
                                    scope.row.sumMoney === 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderKidsHistoryMethod(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            Lịch sử\n          ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        1
      ),
      _c("HistoryOrderKidsDialog", {
        ref: "OrderKidsHistoryDialog",
        attrs: { isOpenHistoryDialog: _vm.showHistoryDialog },
        on: {
          closeHistoryOrderKids: function ($event) {
            return _vm.closeOrderKidsHistoryDialog()
          },
        },
      }),
      _c("ExcelKidDetail", {
        ref: "ExcelKidDetail",
        attrs: {
          isOpenKidDetail: _vm.isOpenKidDetailDialog,
          "kids-detail-data": _vm.kidDetailData,
        },
        on: { closeDetailKid: _vm.handleCloseDetailKid },
      }),
      _c("OrderKidsExcelCashier", {
        ref: "OrderKidsExcelCashier",
        attrs: {
          isOpenCashierDialog: _vm.showOrderKidsExcelCashierDialog,
          isActivatedAccount: _vm.isActivatedAccount,
        },
        on: { "dialog-close": _vm.closeOrderKidsExcelCashierDialog },
      }),
      _vm.checkPermission(["fees_2_excel_cash_payment"])
        ? _c("CollectedMoneyDialog", {
            ref: "CollectedMoneyDialog",
            attrs: { isOpenCollectedMoney: _vm.showCollectedMoney },
            on: {
              closeCollectedMoney: function ($event) {
                return _vm.closeCollectedMoneyDialog()
              },
            },
          })
        : _vm._e(),
      _c("InventoryOrderDialog", {
        ref: "InventoryOrderDialog",
        attrs: { isOpenInventoryOrder: _vm.showInventoryOrder },
        on: {
          closeInventoryOrder: function ($event) {
            return _vm.closeInventoryOrderDialog()
          },
        },
      }),
      _c("DetailKTDialog", {
        ref: "DetailKTDialog",
        attrs: { isOpenDetailKT: _vm.detailKT },
        on: {
          closeDetailKT: function ($event) {
            return _vm.closeDetailKTDialog()
          },
        },
      }),
      _vm.checkPermission(["fees_2_excel_cash_payment"])
        ? _c("ChangeNameRevenusDialog", {
            attrs: {
              isOpenChangeNameRevenusDialog: _vm.modifierLesRevenus,
              nameRevenus: _vm.nameRevenus,
              checkDelete: _vm.checkDelete,
            },
            on: {
              closeChangeNameRevenusDialog: _vm.closeChangeNameRevenusDialog,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }