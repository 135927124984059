<template>
  <div>
    <el-dialog
        :title="'Thanh toán hóa đơn học phí: ' + titleInfoAdd"
        :visible.sync="isOpenCashierDialog"
        :empty-text="textTable"
        width="1200px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="1vh"
    >
      <div
          style="
          margin-bottom: 15px;
          display: inline-block;
          position: relative;
          z-index: 1;
        "
      ></div>
      <div style="text-align: right">
         <span
         >Số tiền tính toán:
        <span v-if="moneyCheckTotal === 0" style="font-weight: bold">0</span>
        <span v-else style="font-weight: bold">{{
            moneyCheckTotal | formatCurrency
          }}</span>
      </span>
      </div>
      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          highlight-current-row
          :data="listPayment"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          :max-height="320"
          @selection-change="handleSelectionChange"
          border
      >
        <el-table-column
            type="selection"
            width="55"
            align="center"
        ></el-table-column>
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="80"
            align="center"
        ></el-table-column>
        <el-table-column
            label="Tên khoản"
            prop="fnPackage.name"
            min-width="180"
            align="left"
        >
          <template v-slot:default="scope">
            <span>{{ scope.row.nameKt }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Số lượng" align="center" width="100">
          <template v-slot:default="scope">
            <span>{{ scope.row.quantityKt }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Đơn giá" align="right" width="150">
          <template v-slot:default="scope">
            <span>{{ scope.row.priceKt | formatCurrency }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Giảm giá" align="right" width="150">
          <template v-slot:default="scope">
            <span>{{ scope.row.discountKt | formatCurrency }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Thành tiền" align="right" width="150">
          <template v-slot:default="scope">
            <span>{{ scope.row.moneyKt | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" width="120">
          <template v-slot:header>
            <div>Tính toán</div>
            <el-checkbox v-model="checkAll" @change="changeAllCheckboxCalculator"></el-checkbox>
          </template>
          <template v-slot:default="scope">
            <el-checkbox v-model="isCheckbox[scope.$index]"
                         @change="changeCheckboxCalculator(scope.$index)"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <div>
        <el-row :gutter="20">
          <el-col :span="6" style="margin-right: 15px">
            <div class="">
              <div class="text-money">
                <span v-if="beforeMoney < 0">Tháng trước thiếu</span>
                <span v-else>Tháng trước thừa</span>

                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="beforeMoney === 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="beforeMoney > 0"
                  >{{ beforeMoney | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="beforeMoney < 0"
                  >{{ Math.abs(beforeMoney) | formatCurrency }}</span
                  >
                </span>
              </div>

              <div class="text-money">
                Tổng tiền tất cả hóa đơn
                <span style="font-weight: bold; float: right">
                  <span v-if="sumMoney === 0">0</span>
                  <span v-else>{{ sumMoney | formatCurrency }}</span>
                </span>
              </div>
              <div class="text-money">
                Tổng tiền đã trả
                <span style="font-weight: bold; float: right">
                  <span v-if="collectedMoney === 0">0</span>
                  <span v-else>{{ collectedMoney | formatCurrency }}</span>
                </span>
              </div>

              <div class="text-money">
                <span v-if="afterMoney < 0">Tiền còn lại phải trả</span>
                <span v-else>Tiền còn lại được nhận</span>

                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="afterMoney === 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="afterMoney > 0"
                  >{{ afterMoney | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="afterMoney < 0"
                  >{{ Math.abs(afterMoney) | formatCurrency }}</span
                  >
                </span>
              </div>

              <hr
                  style="
                  border-bottom: 1px solid #c0c4cc;
                  margin-top: -0.5em;
                  margin-bottom: 0.7em;
                "
              />
              <div class="text-money">
                Tổng tiền nhập
                <span style="font-weight: bold; float: right; color: yellow">
                  <span v-if="totalMoneyKt === 0">0</span>
                  <span v-else>{{ totalMoneyKt | formatCurrency }}</span>
                </span>
              </div>

              <div class="text-money">
                <span v-if="afterMoneyThisMonth < 0">Số tiền còn thiếu</span>
                <span v-else>Tiền thừa</span>
                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="afterMoneyThisMonth === 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="afterMoneyThisMonth > 0"
                  >{{ afterMoneyThisMonth | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="afterMoneyThisMonth < 0"
                  >{{ Math.abs(afterMoneyThisMonth) | formatCurrency }}</span
                  >
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="17" style="float: right">
            <el-form
                label-width="155px"
                :model="dataInput"
                label-position="left"
                :rules="rules"
                ref="dataInput"
            >
              <el-form-item
                  label="Tiền mặt"
                  prop="cash_money"
                  style="display: inline-block; width: 45%"
                  :class="{ 'negative-value': cashMoney < 0 }"
              >
                <el-currency-input
                    :options="customOption"
                    v-model="cashMoney"
                    placeholder="Nhập tiền mặt"
                ></el-currency-input>
              </el-form-item>
              <el-form-item
                  label="Chuyển khoản"
                  prop="transfer_money"
                  style="display: inline-block; width: 45%; float: right"
              >
                <el-currency-input
                    :options="customOption"
                    v-model="transferMoney"
                    placeholder="Nhập tiền chuyển khoản"
                ></el-currency-input>
              </el-form-item>


              <el-form-item
                  label="Người thanh toán"
                  prop="parentPay"
                  style="display: inline-block; width: 45%"
              >
                <el-input
                    v-model="dataInput.parentPay"
                    placeholder="Phụ huynh học sinh"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="Ngày thanh toán"
                  prop="datePayment"
                  style="display: inline-block; width: 45%; float: right"
              >
                <el-date-picker
                    v-model="dataInput.datePayment"
                    type="date"
                    :clearable="false"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    format="dd-MM-yyyy"
                    placeholder="Chọn ngày"
                    :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="Tiền thừa thiếu" prop="transferMoneyType">
                <el-radio-group
                    @change="onChangeRadioTransactionEntry"
                    v-model="dataInput.transferMoneyType"
                    style="margin-top: 12px"
                >
                  <el-radio style="color: black" label="MONEY_WALLET"
                  >Không chuyển
                  </el-radio>
                  <el-radio style="color: black" label="MONEY_MONTH"
                  >Sang tháng sau
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Mô tả" prop="noteHistory">
                <el-input
                    v-model="noteHistory"
                    placeholder="Nhập mô tả"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-checkbox
                    v-if="isActivatedAccount"
                    v-model="dataInput.statusSendNotify"
                >Gửi thông báo thanh toán trên App</el-checkbox>
                <span
                    v-else
                    style="color: #d13b93">Tài khoản chưa được kích hoạt, không thể gửi thông báo thanh toán App</span>
              </el-form-item>
              <br/>
              <br/>
            </el-form>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            size="medium"
            :loading="loadingButton"
            mini
            @click="submitForm()"
        >
          <i class="el-icon-circle-check"/>
          <span>Thanh toán</span>
        </el-button>

        <el-button
            type="success"
            size="medium"
            :loading="loadingPdfButton"
            @click="orderPrintMethodNew"
        >
          <i class="el-icon-printer"/>
          <span>In hóa đơn </span>
        </el-button>

        <el-button type="danger" size="medium" @click="closeDialog()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>

    <!--    Hoành đánh dấu: Mở hộp thoại in hóa đơn-->


  </div>
</template>

<script>

import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "OrderKidExcelCashier",
  // eslint-disable-next-line vue/no-unused-components
  props: {
    isOpenCashierDialog: {
      type: Boolean,
      default: () => false,
    },
    isActivatedAccount: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState('finance2Module', ['dataSearch'])
  },
  data() {
    return {
      selectAllEnabled: true,
      customOption: {
        currency: "VND",
        locale: "de",
        allowNegative: true,
      },
      previewBillParams: {
        guiId: "success",
        date: "",
        idKidList: "", // tham số này chỉ truyền vào 1 id thay vì list id
        collectionTurn: "",
      },
      textTable: this.$tableEmpty,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },


      titleInfoAdd: "",
      date: "",
      moneyPayment: 0,
      showOrderPrintDialog: false,
      multipleSelection: "",

      /**
       * Data nhận từ cha
       */

      kidCode: "",
      collectionTurn: 0,
      month: 0,
      year: 0,

      /**
       * Bắt đầu
       */
      isCheckbox: Array.from({length: 20}, () => true),
      checkAll: true,
      listMoneyKt: [],
      totalMoneyKt: 0,
      isRadio: false,

      /**
       * Data hiển thị trong bảng
       */

      listPayment: [],
      afterMoney: 0,
      beforeMoney: 0,
      sumMoney: 0,
      collectedMoney: 0,
      moneyCheckTotal: 0,
      transactionEntry: "",

      /**
       * Data gửi về để lưu lịch sử thanh toán
       */

      dataInput: {
        idKids: Number,
        collectionTurn: 0,
        collectedMoney: 0,
        cash_money: 0,
        transfer_money: 0,
        afterMoney: 0,
        paymentType: null,
        parentPay: "PHHS",
        date: "",
        datePayment: moment(String(new Date())).format("YYYY-MM-DD"),
        noteHistory: null,
        transferMoneyType: "",
        statusSendNotify: false
      },

      cashMoney: 0,
      transferMoney: 0,
      afterMoneyThisMonth: 0,
      noteHistory: "",
      responseData: {},
      loadingButton: false,
      loadingPdfButton: false,
      rules: {
        cash_money: [
          {
            required: true,
            message: "Tiền mặt không được để trống!",
            trigger: "blur",
          },
        ],

        transfer_money: [
          {
            required: true,
            message: "Tiền chuyển khoản không được để trống!",
            trigger: "blur",
          },
        ],
        parentPay: [
          {required: true, message: 'Phụ huynh học sinh không được để trống!', trigger: 'blur'},
        ],
      },
    };
  },

  watch: {
    /**
     * Theo dõi thay đổi tiền mặt, chuyển khoản
     * @param newValue
     */
    cashMoney(newValue) {
      if (newValue == null) {
        this.dataInput.cash_money = 0;
      } else {
        this.dataInput.cash_money = newValue;
      }
      this.getAfterMoneyThisMonth();
    },
    transferMoney(newValue) {
      if (newValue == null) {
        this.dataInput.transfer_money = 0;
      } else {
        this.dataInput.transfer_money = newValue;
      }

      this.getAfterMoneyThisMonth();
    },

    noteHistory(newValue) {
      this.dataInput.noteHistory = newValue;
    },
  },

  methods: {

    calculateTotalMoney() {
      this.moneyCheckTotal = this.isCheckbox.reduce((total, isChecked, index) => {
        if (isChecked) {
          return total + this.listPayment[index].moneyKt;
        } else {
          return total;
        }
      }, 0);
    },
    changeAllCheckboxCalculator() {
      this.isCheckbox = this.isCheckbox.map(() => this.checkAll);
      this.calculateTotalMoney();
    },
    /**
     * Xử lý khi lựa chọn checkbox tính toán
     */

    changeCheckboxCalculator(index) {
      let moneyKt = this.listPayment[index].moneyKt;
      if (this.isCheckbox[index]) {
        this.moneyCheckTotal += moneyKt;
      } else {
        this.moneyCheckTotal -= moneyKt;
      }
      this.checkAll = this.isCheckbox.every((isChecked) => isChecked);
      this.calculateTotalMoney();
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.paid === 0) {
        return "color: #F56C6C";
      } else if (row.paid < row.money) {
        return "color: #E6A23C";
      }
    },

    clearData() {
      this.loadingButton = false;
      this.checkNotOrder = false;
      this.listPayment = [];
      this.afterMoney = 0;
      this.beforeMoney = 0;
      this.sumMoney = 0;
      this.collectedMoney = 0;
      this.moneyCheckTotal = 0;
      this.isCheckbox = [];
      this.listMoneyKt = [];
      this.totalMoneyKt = 0;
      this.isRadio = false;
      this.cashMoney = 0;
      this.transferMoney = 0;
      this.afterMoneyThisMonth = 0;
      this.noteHistory = "";
      this.cashMoney = 0;
      this.transferMoney = 0;
      this.transactionEntry = "";
      this.$refs["dataInput"].resetFields();
    },

    /**
     * Đóng Dialog
     */

    closeDialog() {
      this.$emit("dialog-close");
      this.checkAll = true;
      setTimeout(() => {
        this.clearData();
      }, 300);
    },

    closeOrderPrintDialog() {
      this.showOrderPrintDialog = false;
      setTimeout(() => {
        this.loadingPdfButton = false;
      }, 1500);
      this.reloadMethod();
    },


    /**
     * Hàm in hóa đơn : Chờ Dialog in hóa đơn
     */
    orderPrintMethodNew() {
      this.checkButtonBefore();
      this.$store.dispatch(
          "finance2Module/preview_info_for_one_kid",
          this.previewBillParams
      ); // gọi api để lấy thông tin hóa đơn ...

    },

    checkButtonBefore() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "Không có khoản thu nào được chọn",
          type: "error",
        });
        throw null;
      }
    },
    reloadMethod() {
      // setTimeout(() => {
      // location.reload();
      // }, 6000);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$store.commit("finance2Module/bill_table_for_one_kid", val);
    },

    /**
     * Nhận dữ liệu từ cha, khởi tạo dữ liệu hiển thị ra màn hình
     * @param data
     */
    getDataCashierInitial(
        idKid,
        kidCode,
        kidName,
        month,
        collectionTurn,
        orderKidCode,
        year
    ) {


      // tham số in hơnóa đơn
      this.previewBillParams.date = this.$funcCommon.formatMY(year, month);
      this.previewBillParams.collectionTurn = collectionTurn;
      this.previewBillParams.idKidList = idKid;
      // thông tin lên màn hình
      this.titleInfoAdd = kidName + " - " + orderKidCode + " - D" + collectionTurn;
      this.dataInput.idKids = idKid;
      this.dataInput.collectionTurn = collectionTurn;
      this.kidCode = kidCode;
      this.collectionTurn = collectionTurn;
      this.month = month;
      this.year = year


      if (month < 10) {
        this.dataInput.date = year + "-0" + month + "-15";
      } else {
        this.dataInput.date = year + "-" + month + "-15";
      }
      financeKidsV2Service
          .showAllPaymentForKid(kidCode, collectionTurn, month, year)
          .then((res) => {
            this.listPayment = res.data.paymentDetails;
            this.$refs.multipleTable.toggleAllSelection()
            this.afterMoney = res.data.afterMoney;
            this.sumMoney = res.data.sumMoney;
            this.collectedMoney = res.data.collectedMoney;
            this.dataInput.transferMoneyType = res.data.transferMoneyType;
            this.beforeMoney = res.data.beforeMoney;
            this.isCheckbox = Array.from({length: this.listPayment.length}, () => true);
            this.calculateTotalMoney()
            this.afterMoneyThisMonth = this.dataInput.cash_money + this.dataInput.transfer_money + this.afterMoney;

          })
          .catch(() => {
            this.$message({
              message: "Thất bại",
              type: "error",
            });
          });
    },

    /**
     * Load lại thông tin sau khi thanh toán
     */
    getInformationAfterPayment() {
      this.dataInput.parentPay = "PHHS";
      this.noteHistory = "";
      this.dataInput.statusSendNotify = false;
      this.dataInput.paymentType = "";
      this.totalMoneyKt = 0;
      this.cashMoney = 0;
      this.transferMoney = 0;

      financeKidsV2Service
          .showAllPaymentForKid(this.kidCode, this.collectionTurn, this.month, this.year)
          .then((res) => {
            this.listPayment = res.data.paymentDetails;
            this.afterMoney = res.data.afterMoney;
            this.beforeMoney = res.data.beforeMoney;
            this.sumMoney = res.data.sumMoney;
            this.collectedMoney = res.data.collectedMoney;
            this.loadingButton = false;
            this.afterMoneyThisMonth = 0;
          })
          .then(() => {
            this.$store.commit('finance2Module/get_status_excel')
          })
          .catch(() => {
            this.$message({
              message: "Thất bại",
              type: "error",
            });
          });
    },

    /**
     * Thay đổi chọn radio loại thanh toán
     */

    onChangeRadio() {
      if (this.dataInput.paymentType === "CASH") {
        this.dataInput.cash_money = this.totalMoneyKt;
        this.dataInput.transfer_money = 0;

        this.getAfterMoneyThisMonth();
      }
      if (this.dataInput.paymentType === "TRANSFER") {
        this.dataInput.transfer_money = this.totalMoneyKt;
        this.dataInput.cash_money = 0;

        this.getAfterMoneyThisMonth();
      }
    },

    /**
     * Thay đổi chọn radio trạng thái kết chuyển
     */
    onChangeRadioTransactionEntry() {
      this.transactionEntry = this.dataInput.transferMoneyType;
    },

    /**
     * Lấy tiền thiếu thừa còn lại tháng này
     */
    getAfterMoneyThisMonth() {
      this.afterMoneyThisMonth = this.dataInput.cash_money + this.dataInput.transfer_money + this.afterMoney;
      this.totalMoneyKt = this.dataInput.cash_money + this.dataInput.transfer_money;

    },

    /**
     * Gửi form lên để lưu dữ liệu vào DB
     */

    submitForm() {
      this.dataInput.collectedMoney = this.totalMoneyKt;
      this.dataInput.afterMoney = this.afterMoneyThisMonth;
      let dataSubmit = {
        idKids: Number,
        collectionTurn: Number,
        collectedMoney: Number,
        cash_money: Number,
        transfer_money: Number,
        afterMoney: Number,
        parentPay: String,
        date: "",
        datePayment: null,
        noteHistory: String,
        transactionEntry: String,
        statusSendNotify: Boolean,

      };

      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          let moneyInputLocal = this.$funcCommon.formatMoney(this.totalMoneyKt);
          this.$confirm(
              "Bạn có chắc chắn muốn thanh toán với tổng tiền là " +
              moneyInputLocal +
              " VNĐ",
              {
                distinguishCancelAndClose: true,
                confirmButtonText: "Có",
                closeOnClickModal: false,
                cancelButtonText: "Không",
              }
          )
              .then(() => {
                dataSubmit.idKids = this.dataInput.idKids;
                dataSubmit.collectionTurn = this.dataInput.collectionTurn;
                dataSubmit.collectedMoney = this.dataInput.collectedMoney;
                dataSubmit.cash_money = this.dataInput.cash_money;
                dataSubmit.transfer_money = this.dataInput.transfer_money;
                dataSubmit.afterMoney = this.dataInput.afterMoney;
                dataSubmit.parentPay = this.dataInput.parentPay;
                dataSubmit.date = this.dataInput.date;
                dataSubmit.datePayment = this.dataInput.datePayment;
                dataSubmit.noteHistory = this.dataInput.noteHistory;
                dataSubmit.transactionEntry = this.dataInput.transferMoneyType;
                dataSubmit.statusSendNotify = this.dataInput.statusSendNotify;
                financeKidsV2Service
                    .saveKidPaymentForTurn(dataSubmit)
                    .then((resp) => {
                      this.$message({
                        message: resp.data.message,
                        type: "success",
                      });
                      this.getInformationAfterPayment();
                    })
                    .catch((resp) => {
                      this.$message({
                        message: resp.data.message,
                        type: "error",
                      });
                    });
              })
              .catch(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 300);
              });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  word-break: break-word;
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-dialog__body {
  padding-bottom: 5px;
}

/deep/ .el-form-item__label {
  color: black;
}

.el-row {
  margin-top: 15px;
  padding-top: 20px;
  background: #97bee7;
  color: black;
  border-radius: 5px;
}

.input-common {
  margin-right: 5px;
}

.text-money {
  margin-bottom: 15px;
}

/deep/ .el-form-item__content {
  line-height: 0;
}

/deep/ .negative-value .el-input__inner {
  color: red;
}
</style>